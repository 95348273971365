import COS from 'cos-js-sdk-v5'
import AWS from 'aws-sdk'
import OSS from 'ali-oss'
import axios from 'axios'
import Utils from '../Utils'
import api from '@/http'
import ObsClient from '../../../static/huawei/esdk-obs-browserjs-without-polyfill-3.19.5.min'
import { allWithProgress } from '@/utils'
import vm from '@/main'
import * as qiniu from 'qiniu-js'
import { ksyunDirectUploadSdk, multipartUpload } from '@/api/network/ksyunUpload'
import { cloneDeep } from 'lodash'
import wgBaseUpload from './wgUpload'
import { streamUpload } from './Uploader'

let async = require('async')

let taskInfos = []

function getTaskInfos () {
  return taskInfos
}

function addTaskInfo (inTaskInfo) {
  taskInfos.push(inTaskInfo)
}

function removeTaskInfo (inTaskInfo) {
  let removeTaskInfo
  if (inTaskInfo.keyUUID !== undefined) {
    removeTaskInfo = taskInfos.find(param => param.keyUUID === inTaskInfo.keyUUID)
  } else if (inTaskInfo.jobId > 0) {
    removeTaskInfo = taskInfos.find(param => param.jobId === inTaskInfo.jobId)
  }
  if (removeTaskInfo) {
    taskInfos.splice(taskInfos.indexOf(removeTaskInfo), 1)
  }
}

/**
 * createdAt    jobId      jobType
 progress     projectId  resourceId
 resourceType status     workerId
 * @param inTaskInfo
 */
function updateTaskInfo (inTaskInfo) {
  let index = -1
  if (inTaskInfo.keyUUID !== undefined) {
    for (let i = 0; i < taskInfos.length; i++) {
      if (taskInfos[i].keyUUID === inTaskInfo.keyUUID) {
        index = i
        break
      }
    }
  } else if (inTaskInfo.jobId > 0) {
    for (let i = 0; i < taskInfos.length; i++) {
      if (taskInfos[i].jobId === inTaskInfo.jobId) {
        index = i
        break
      }
    }
  }
  if (!taskInfos[index]) return
  if (inTaskInfo.createdAt != null) {
    taskInfos[index].createdAt = inTaskInfo.createdAt
  }
  if (inTaskInfo.title != null) {
    taskInfos[index].title = inTaskInfo.title
  }
  if (inTaskInfo.jobId != null) {
    taskInfos[index].jobId = inTaskInfo.jobId
  }
  if (inTaskInfo.jobType != null) {
    taskInfos[index].jobType = inTaskInfo.jobType
  }
  if (inTaskInfo.progress != null) {
    taskInfos[index].progress = inTaskInfo.progress
  }
  if (inTaskInfo.projectId != null) {
    taskInfos[index].projectId = inTaskInfo.projectId
  }
  if (inTaskInfo.resourceId != null) {
    taskInfos[index].resourceId = inTaskInfo.resourceId
  }
  if (inTaskInfo.resourceType != null) {
    taskInfos[index].resourceType = inTaskInfo.resourceType
  }
  if (inTaskInfo.status != null) {
    taskInfos[index].status = inTaskInfo.status
  }
  if (inTaskInfo.workerId != null) {
    taskInfos[index].workerId = inTaskInfo.workerId
  }
  if (inTaskInfo.m3u8Url != null) {
    taskInfos[index].m3u8Url = inTaskInfo.m3u8Url
  }
  if (inTaskInfo.jobId != null) {
    taskInfos[index].m3u8LocalPath = inTaskInfo.m3u8LocalPath
  }
  if (inTaskInfo.keyUUID != null) {
    taskInfos[index].keyUUID = inTaskInfo.keyUUID
  }
  if (inTaskInfo.uploadType != null) {
    taskInfos[index].uploadType = inTaskInfo.uploadType
  }
}

let uploadRecordInfos = []
// {name: FS路径}
function getRecordInfos () {
  return uploadRecordInfos
}

function addRecordInfo (inTaskInfo) {
  uploadRecordInfos.push(inTaskInfo)
}

function removeRecordInfo (inTaskInfo) {
  let removeTaskInfo = uploadRecordInfos.find(param => param.name === inTaskInfo.name)
  if (removeTaskInfo) {
    uploadRecordInfos.splice(uploadRecordInfos.indexOf(removeTaskInfo), 1)
  }
}

let aliOssClient = null
function getAliOssClient () {
  return aliOssClient
}
function setAliOssClient (_aliOssClient) {
  aliOssClient = _aliOssClient
}

export default {
  getTaskInfos,
  addTaskInfo,
  removeTaskInfo,
  updateTaskInfo,
  getRecordInfos,
  addRecordInfo,
  removeRecordInfo,
  getAliOssClient,
  setAliOssClient,
  data () {
    return {
      // NOTE: 上传及打包任务相关
      webApi_sts_info: Utils.getApiHost('sts') + 'sts/info',
      webApi_sts_info2: Utils.getApiHost('sts') + 'upload/sts/info',
      webApi_sts_upload_local: Utils.getApiHost('sts') + 'upload/files',
      webApi_job_info: Utils.getApiHost('job') + 'job/batch/info',
      webApi_job_list: Utils.getApiHost('job') + 'job/list',
      webApi_job_delete: Utils.getApiHost('job') + 'job/delete',
      webApi_job_retry: Utils.getApiHost('job') + 'job/retry',
      webApi_job_transcode: Utils.getApiHost('job') + 'job/transcode/createBatchJobs',

      // NOTE: 用户相关
      webApi_user_list: Utils.getApiHost('user') + 'user/list',
      webApi_userInfo: Utils.getApiHost('user') + 'user/info',
      webApi_login: Utils.getApiHost('user') + 'login',
      webApi_register: Utils.getApiHost('user') + 'usercenter/register',
      webApi_checkPhone: Utils.getApiHost('user') + 'usercenter/verify_code/check',
      webApi_checkEmail: Utils.getApiHost('user') + 'usercenter/user/check/email',
      webApi_checkPassword: Utils.getApiHost('user') + 'usercenter/user/update',
      webApi_forwardToEmail: Utils.getApiHost('user') + 'usercenter/verifyCode/forward/phone/to/email',
      webApi_judgeUserExist: Utils.getApiHost('user') + 'usercenter/user/exist',
      webApi_verify_code_create: Utils.getApiHost('user') + 'usercenter/verify_code/create',
      webApi_verify_code_image_create: Utils.getApiHost('user') + 'usercenter/verify_code_image/create',

      webApi_user_bind: Utils.getApiHost('user') + 'user/thirdPart/bind', // 绑定第三方账号
      webApi_user_unbind: Utils.getApiHost('user') + 'user/thirdPart/unbind', // 解绑第三方账号
      webApi_user_third_part_info: Utils.getApiHost('user') + 'user/thirdPart/info', // 查询绑定的第三方账号

      // NOTE: 资源相关
      webApi_resource_create: Utils.getApiHost('resource') + 'resource/create',
      webApi_resource_update: Utils.getApiHost('resource') + 'resource/update',
      webApi_resource_list: Utils.getApiHost('resource') + 'resource/list',
      webApi_resource_info: Utils.getApiHost('resource') + 'resource/info',
      webApi_resource_tag_info: Utils.getApiHost('resource') + 'resource/tag/info',
      webApi_resource_delete: Utils.getApiHost('resource') + 'resource/delete',
      webApi_resource_batch_delete: Utils.getApiHost('resource') + 'resource/batchDelete',
      webApi_resource_append: Utils.getApiHost('resource') + 'resource/append',
      webApi_resource_add: Utils.getApiHost('resource') + 'resource/add',
      webApi_resource_updtitle: Utils.getApiHost('resource') + 'resource/updtitle',
      webApi_resource_reverse: Utils.getApiHost('resource') + 'resource/reverse',

      // NOTE: 视频同步
      webApi_audio_align: Utils.getApiHost('audio') + 'resource/audio/compare',
      webApi_audio_result: Utils.getApiHost('audio') + 'resource/audio/cmpresult',

      // NOTE: 成片相关
      webApi_video_list: Utils.getApiHost('video') + 'video/list',
      webApi_video_delete: Utils.getApiHost('video') + 'video/delete',
      webApi_video_create: Utils.getApiHost('video') + 'video/create',
      webApi_video_share: Utils.getApiHost('video') + 'video/share',
      webApi_video_share_info: Utils.getApiHost('video') + 'video/share/info',

      // NOTE: 素材相关
      webApi_material_list: Utils.getApiHost('material') + 'materialNew/list',
      webApi_material_all: Utils.getApiHost('material') + 'materialNew/all',
      webApi_material_categories: Utils.getApiHost('material') + 'materialNew/categories',
      webApi_material_categories_multi: Utils.getApiHost('material') + 'materialNew/back/categories',
      webApi_material_add: Utils.getApiHost('material') + 'materialNew/user/add',
      webApi_material_listPrivate: Utils.getApiHost('material') + 'materialcenter/material/listPrivate',
      webApi_material_listAuthed: Utils.getApiHost('material') + 'materialcenter/material/listAuthed',
      webApi_material_listAll: Utils.getApiHost('material') + 'materialcenter/material/listAll',
      webApi_material_listTypeAndCategory: Utils.getApiHost('material') + 'material/listTypeAndCategory',
      webApi_material_arrearage: Utils.getApiHost('material') + 'materialcenter/material/arrearageMaterial',
      webApi_material_submitMaterial: Utils.getApiHost('material') + 'materialcenter/material/submitMaterial',

      // NOTE: 工程相关
      webApi_project_list: Utils.getApiHost('project') + 'project/list',
      webApi_project_info: Utils.getApiHost('project') + 'project/info',
      webApi_project_create: Utils.getApiHost('project') + 'project/create',
      webApi_project_create_empty: Utils.getApiHost('project') + 'project/create/empty',
      webApi_project_update: Utils.getApiHost('project') + 'project/update',
      webApi_project_delete: Utils.getApiHost('project') + 'project/delete',
      webApi_project_compile: Utils.getApiHost('project') + 'project/compile',
      webApi_project_history_list: Utils.getApiHost('project') + 'project/history/list',
      webApi_project_history_save: Utils.getApiHost('project') + 'project/history/save',
      webApi_project_clone: Utils.getApiHost('project') + 'project/clone',
      webApi_project_version_list: Utils.getApiHost('project') + 'version/list',

      // 三端互通
      three_terminal_project_create: Utils.getApiHost('project') + 'threeTerminalProject/create',
      three_terminal_project_update: Utils.getApiHost('project') + 'threeTerminalProject/update',

      // NOTE: 直播相关
      webApi_project_reset: Utils.getLiveApiHost() + 'm3u8Transform/resetMeiShe',
      webApi_live_project_compile: Utils.getLiveApiHost() + 'project/compile',
      webApi_live_compile: `${Utils.getLiveApiHost()}project/compile`,
      webApi_live_job_info: `${Utils.getLiveApiHost()}job/info`,
      webApi_live_job_delete: `${Utils.getLiveApiHost()}cancelCompilingJob`,
      webApi_live_getLiveResourceByOriginUrl: Utils.getLiveApiHost() + 'm3u8Transform/getLiveResourceByOriginUrl',
      webApi_live_publish: Utils.getApiHost('yicai') + 'meishe/live/create',
      webApi_live_refreshAuthKey: Utils.getLiveApiHost() + 'm3u8Transform/refreshM3u8UrlAuthKey',
      webApi_live_stopLiveResource: Utils.getLiveApiHost() + 'm3u8Transform/stopLiveResource',
      webApi_live_audio_to_text_start: Utils.getLiveApiHost() + 'm3u8Transform/startConnecting',
      webApi_live_audio_to_text_end: Utils.getLiveApiHost() + 'm3u8Transform/endConnecting',

      // 语言转字幕 讯飞语音转写
      webApi_recognition_create: Utils.getApiHost('recognition') + 'recognition/create',
      webApi_recognition_info: Utils.getApiHost('recognition') + 'recognition/info',

      // 发布
      // 微博
      weibo_requestAuth: 'oauth2/authorize', // 请求授权
      weibo_getAuth: 'oauth2/access_token', // 获取授权
      weibo_share: '2/statuses/share.json', // 分享
      weibo_getTokenInfo: 'oauth2/get_token_info', // 查看授权信息
      weibo_revokeAuth: 'oauth2/revokeoauth2', // 回收授权
      weibo_userInfo: '2/users/show.json', // 查看已授权用户信息
      weibo_likeCount: '2/interest/like_count.json', // 根据URL批量获取对象的总赞数

      // 智能标签
      tag_all: Utils.getApiHost('tag') + 'tag/all',
      tag_fuzzySearch: Utils.getApiHost('tag') + 'tag/fuzzySearch',
      tag_create: Utils.getApiHost('tag') + 'tag/create',

      // SMG 上传相关接口
      upload_config: Utils.getApiHost('upload') + 'upload/config',
      upload_info: Utils.getApiHost('upload') + 'upload/info',
      upload_steam: window.$nvsConfig.streamUpload + 'streamUpload',


      // 闻歌 上传相关
      wg_upload: '',
      wg_i_upload: '',
      wg_media: 'https://hongqiplus.wengegroup.com/mam/api/search/highLevelSearchShareAssets',
    }
  },
  uploadAliOss (options = {}) {
    if (process.env.NODE_ENV === 'aws') {
      return this.awsUpload(options)
    } else if (process.env.NODE_ENV === 'qiniu') {
      return this.qiniuUpload(options)
    } else if (process.env.NODE_ENV === 'huawei') {
      return this.huaweiUpload(options)
    } else if (process.env.NODE_ENV === 'local') {
      return this.localUpload(options)
    } else if (process.env.NODE_ENV === 'ksyun') {
      return this.ksyunUpload(options)
    } else if (process.env.NODE_ENV === 'wenge') {
      return this.wgUpload(options)
    } else if ([process.env.NODE_ENV, process.env.CONFIG_NAME].includes('smg')) {
      const { keyForSMG, keyUUID, file, callback, errorCb } = options
      return streamUpload(keyForSMG, keyUUID, file, callback, errorCb)
    } else if ([process.env.NODE_ENV, process.env.CONFIG_NAME].includes('pku')){
      return this.localUpload(options)
    }
    // 默认用哪个存储这里使用哪个方法
    this.aliyunUpload2(options)
  },
  baiduUpload (key, uuid, file, progressCallback, error) {
    const url = this.data().webApi_sts_info
    axios.get(url, {params: {type: 4}}).then(response => {
      console.log(response)
      const config = {
        endpoint: response.data.data.endpoint,
        credentials: {
          ak: response.data.data.accessKeyId,
          sk: response.data.data.secretAccessKey
        },
        sessionToken: response.data.data.securityToken
      }

      let bucket = 'testbsedit'
      // eslint-disable-next-line no-undef
      let client = new baidubce.sdk.BosClient(config)

      let filename = file.name
      let ext = filename.split(/\./g).pop()
      // eslint-disable-next-line no-undef
      let mimeType = baidubce.sdk.MimeType.guess(ext)
      if (/^text\//.test(mimeType)) {
        mimeType += '; charset=UTF-8'
      }
      let options = {
        'Content-Type': mimeType
      }
      console.log(options)

      let PART_SIZE = 5 * 1024 * 1024 // 指定分块大小

      function getTasks (file, uploadId, bucketName, key) {
        let leftSize = file.size
        let offset = 0
        let partNumber = 1

        let tasks = []

        while (leftSize > 0) {
          let partSize = Math.min(leftSize, PART_SIZE)
          tasks.push({
            file: file,
            uploadId: uploadId,
            bucketName: bucketName,
            key: key,
            partNumber: partNumber,
            partSize: partSize,
            start: offset,
            stop: offset + partSize - 1
          })

          leftSize -= partSize
          offset += partSize
          partNumber += 1
        }
        return tasks
      }

      function uploadPartFile (state, client) {
        return function (task, callback) {
          let blob = task.file.slice(task.start, task.stop + 1)
          client.uploadPartFromBlob(task.bucketName, task.key, task.uploadId, task.partNumber, task.partSize, blob)
            .then(function (res) {
              ++state.loaded
              callback(null, res)

              let progressData = {}
              progressData.keyUUID = uuid
              progressData.progress = Math.round(state.loaded / state.total * 100)
              progressData.key = key
              progressData.filename = file.name
              progressData.uploadFinish = Math.round(state.loaded / state.total * 100) === 100
              progressCallback(progressData)
            })
            .catch(function (err) {
              error(err)
              console.log(err)
            })
        }
      }

      let uploadId
      client.initiateMultipartUpload(bucket, key, options)
        .then(function (response) {
          uploadId = response.body.uploadId // 开始上传，获取服务器生成的uploadId

          // eslint-disable-next-line no-undef
          let deferred = baidubce.sdk.Q.defer()
          let tasks = getTasks(file, uploadId, bucket, key)
          let state = {
            lengthComputable: true,
            loaded: 0,
            total: tasks.length
          }

          // 为了管理分块上传，使用了async（https://github.com/caolan/async）库来进行异步处理
          let THREADS = 2 // 同时上传的分块数量
          async.mapLimit(tasks, THREADS, uploadPartFile(state, client), function (err, results) {
            if (err) {
              deferred.reject(err)
            } else {
              deferred.resolve(results)
              console.log(results)
            }
          })
          return deferred.promise
        })
        .then(function (allResponse) {
          let partList = []
          allResponse.forEach(function (response, index) {
            // 生成分块清单
            partList.push({
              partNumber: index + 1,
              eTag: response.http_headers.etag
            })
          })
          return client.completeMultipartUpload(bucket, key, uploadId, partList) // 完成上传
        })
        .then(function (res) {
          console.log(res)
          // 上传完成
          let progressData = {}
          progressData.keyUUID = uuid
          progressData.progress = 100
          progressData.key = key
          progressData.filename = file.name
          progressData.uploadFinish = true
          progressData.Location = res.body.location
          progressCallback(progressData)
        })
        .catch(function (err) {
          // 上传失败，添加您的代码
          console.error(err)
        })
    }).catch(err => {
      if (error && typeof error === 'function') error(err)
      console.log(err)
    })
  },
  // 开始分片上传。
  async  aliMultipartUpload (client, file, key, progress, error) {
    try {
      let fileOrBlob = file
      if (typeof file === 'string') {
        fileOrBlob = new Blob([file], {type: 'text/plain;charset=utf-8'})
      }
      // object-name可以自定义为文件名（例如file.txt）或目录（例如abc/test/file.txt）的形式，实现将文件上传至当前Bucket或Bucket下的指定目录。
      const result = await client.multipartUpload(key, fileOrBlob, {
        progress,
        // meta是用户自定义的元数据，通过head接口可以获取到Object的meta数据。
        meta: {
          year: 2020,
          people: 'test'
        }
      })
      console.log(result)
    } catch (e) {
      console.error(e)
      // 捕获超时异常。
      if (e.code === 'ConnectionTimeoutError') {
        console.log('TimeoutError')
        // do ConnectionTimeoutError operation
      }
      if (error && typeof error === 'function') {
        console.error('分片上传失败:', e.code)
        error(e)
      }
    }
  },
  async aliyunUpload2 (options = {}) {
    const {
      title,
      projectId,
      keyUUID,
      file,
      isNeedCallback = 0,   // 后端是否支持回调
      extension,
      isDir = 0,
      isMXF = 0,
      uploadModule,
      callback,
      errorCb,
      uuid
    } = options
    // 测试环境下，不使用回调
    if (process.env.CONFIG_NAME === 'test') {
      isNeedCallback = 0
    }
    try {
      const requestParams = {
        type: Enum.ossType.ali,
        extension,
        isNeedCallback,
        uploadModule,
        isDir,
        ...(isMXF ? { callbackTimes: Array.from(file).length } : {})
      }
      if (uploadModule === Enum.uploadModule.bsProject) {
        requestParams.projectId = projectId
      }
      if ([Enum.uploadModule.template, Enum.uploadModule.multi_project].includes(uploadModule)) {
        requestParams.uuid = uuid
      }
      const response = await api.resource.sts_info2(requestParams)
      // 下面这段为了开发环境调试模板上传
      // let response
      // if (
      //   process.env.NODE_ENV === 'development' &&
      //   uploadModule === Enum.uploadModule.template
      // ) {
      //   const retData = await axios.post(
      //     'https://testeditor.meishesdk.com:446/private/upload/sts/info',
      //     requestParams
      //   )
      //   response = retData.data.data
      // } else {
      //   response = await api.resource.sts_info2(requestParams)
      // }
      // console.log(response)
      try {
        const ossParam = {
          region: response.region,
          accessKeyId: response.accessKeyId,
          accessKeySecret: response.secretAccessKey,
          stsToken: response.securityToken,
          bucket: response.bucket,
          cname: true
        }
        if (process.env.CONFIG_NAME === 'yicai') {
          ossParam.endpoint = response.endpoint
        } else {
          ossParam.endpoint = Utils.getCOSHost()
        }
        const client = new OSS(ossParam)
        this.setAliOssClient(client)
        let fileOrBlob = file
        if (typeof file === 'string') {
          fileOrBlob = new Blob([file], {type: 'text/plain;charset=utf-8'})
        }
        const callbackInfo = response.callbackInfo || {}
        const callbackObj = {
          url: callbackInfo.callbackUrl,
          body: callbackInfo.callbackBody,
          contentType: callbackInfo.callbackBodyType
        }
        if (isMXF) {
          const promiseQueue = []
          const progressList = []
          const fileLength = file.length
          Array.from(file).forEach((fileOne, index) => {
            promiseQueue.push(client.multipartUpload(
              response.relativePath.replace(/(dir\/)\*$/, '$1' + fileOne.name),
              fileOne,
              {
                headers: {
                  'Cache-Control': 'public'
                },
                progress(p) {
                  progressList[index] = p
                  const sum = progressList.reduce((prev, cur) => prev + cur,0)
                  vm.$bus.$emit(vm.$EventBusKey.MediaUpload, JSON.stringify({
                    title,
                    keyUUID,
                    progress: Math.round(sum / fileLength *  100),
                    uploadType: 0
                  }))
                },
                callback: callbackObj
              }
            ))
          })
          const allResults = await allWithProgress(promiseQueue, function (index) {})
          let Location = Utils.getCOSHost() + '/' + allResults[0].name
          Location = Location.replace(/https:\/\/|http:\/\//, '')

          // eslint-disable-next-line standard/no-callback-literal
          callback({ // 全部上传完成调用callback resolve掉
            resourceId: response.objectId,
            Location
          })
        } else if (isDir) {
          const promiseQueue = []
          Array.from(file).forEach((fileOne, index) => {
            promiseQueue.push(client.put(
              response.relativePath.replace(/(dir\/)\*$/, '$1' + fileOne.name),
              fileOne,
              {
                headers: {
                  'Cache-Control': 'public'
                }
              }
            ))
          })
          const allResults = await allWithProgress(promiseQueue, function (index) {
            vm.$bus.$emit(vm.$EventBusKey.MediaUpload, JSON.stringify({
              title,
              keyUUID,
              progress: Math.round(index / (file.length - 1) * 100),
              uploadType: 0
            }))
          })
          // eslint-disable-next-line standard/no-callback-literal
          callback({
            resourceId: response.objectId,
            Location: allResults[0].url.replace(/https:\/\/|http:\/\//, '')
          })
        } else if (isNeedCallback === 0) {
          const result = await client.put(
            response.relativePath,
            fileOrBlob,
            {
              headers: {
                'Cache-Control': 'public'
              }
            }
          )
          // https://quanshi-live.oss-cn-beijing.aliyuncs.com/test/resource/image/2021/07/26/12017/05e58486-be14-4bbc-82cb-e3c63db04c3b.jpg
          let url = result.url.replace(/(.*.com)/, Utils.getCOSHost())
          // eslint-disable-next-line standard/no-callback-literal
          callback({
            resourceId: response.objectId,
            Location: result.url.replace(/https:\/\/|http:\/\//, ''),
            uploadFinish: true,
            projectId: response.projectId
          })
        } else {
          let finish = false
          const result = await client.multipartUpload(
            response.relativePath,
            fileOrBlob,
            {
              // progress: (p, _checkpoint) => {
              progress: (...params) => {
                const p = params[0]
                let progressData = {}
                progressData.keyUUID = keyUUID
                progressData.progress = Math.round(p * 100)
                progressData.filename = file.name
                progressData.resourceId = response.objectId
                progressData.key = response.relativePath
                progressData.uploadFinish = false
                progressData.Location = Utils.getCOSHost() + '/' + response.relativePath.replace(/(dir\/)\*$/, '$1' + file.name)
                progressData.Location = progressData.Location.replace(/https:\/\/|http:\/\//, '')
                callback(progressData)
              },
              headers: {
                'Cache-Control': 'public'
              },
              callback: callbackObj
            }
          )
          // console.log(result)
          if (result.data.code === 0 && !finish) {
            callback({
              keyUUID,
              progress: 100,
              filename: file.name,
              resourceId: response.objectId,
              key: response.relativePath,
              uploadFinish: true,
              Location: (Utils.getCOSHost() + '/' + response.relativePath.replace(/(dir\/)\*$/, '$1' + file.name)).replace(/https:\/\/|http:\/\//, '')
            })
            finish = true
          }
        }
      } catch (e) {
        console.error(e)
        // 捕获超时异常。
        if (e.code === 'ConnectionTimeoutError') {
          console.log('TimeoutError')
          // do ConnectionTimeoutError operation
        }
        if (errorCb && typeof errorCb === 'function') {
          console.error('分片上传失败:', e.code)
          errorCb(e)
        }
      }
    } catch (message) {
      if (errorCb && typeof errorCb === 'function') errorCb(message)
    }
  },
  async localUpload (options = {}) {
    const {
      title,
      projectId,
      keyUUID,
      file,
      isNeedCallback = 0,
      extension,
      isDir = 0,
      isMXF = 0,
      uploadModule,
      callback,
      errorCb,
      uuid
    } = options
    try {
      const requestParams = {
        type: Enum.ossType.local,
        extension,
        isNeedCallback,
        uploadModule,
        isDir
      }
      if (uploadModule === Enum.uploadModule.bsProject) {
        requestParams.projectId = projectId
      }
      if ([Enum.uploadModule.template, Enum.uploadModule.multi_project].includes(uploadModule)) {
        requestParams.uuid = uuid
      }
      const response = await api.resource.sts_info2(requestParams)
      const formData = new FormData()

      if (file instanceof Blob) {
        formData.append('files', file)
      } else {
        const blob = new Blob([file], { type: 'text/plain' })
        const xmlFile = new File(
          [blob],
          response.relativePath.replace(/^.*\/([^\/]+)$/, '$1')
        )
        formData.append('files', xmlFile)
      }
      formData.append('keys', response.relativePath)

      const uploadResponse =await api.resource.upload_file_local(
        formData,
        {},
        function (progressData){
          if (progressData.lengthComputable) {
            const progress = Math.round(
              (progressData.loaded * 100) / progressData.total
            )
            callback({
              resourceId: response.objectId,
              filename: file.name,
              progress,
              projectId: response.projectId
            })
          }
        }
      )

      const data = {
        keyUUID : keyUUID,
        resourceId: response.objectId,
        Location: uploadResponse.urls[0].replace(
          /https:\/\/|http:\/\//,
          ''
        ),
        projectId: response.projectId,
        uploadFinish:true,
        progress:100
      }
      callback(data)
    } catch (message) {
      if (errorCb && typeof errorCb === 'function') errorCb(message)
    }
  },
  // 腾讯云上传 get sts_info type=1或不传
  txUpload (key, uuid, file, callback, error) {
    const url = this.data().webApi_sts_info
    axios.get(url, {params: {type: 1}}).then(response => {
      // console.log(response)
      if (response.data.code === 0) {
        let cos = new COS({
          SecretId: response.data.data.credentials.tmpSecretId,
          SecretKey: response.data.data.credentials.tmpSecretKey,
          XCosSecurityToken: response.data.data.credentials.sessionToken
        })

        cos.putObject({
          Bucket: 'bsedit-1257467088',
          Region: 'ap-beijing',
          Key: key,
          Body: file,
          onProgress: function (progressData) {
            progressData.keyUUID = uuid
            progressData.uploadFinish = false
            progressData.progress = Math.round((progressData.loaded * 100) / progressData.total)
            progressData.key = key
            progressData.filename = file.name
            // console.log('TX progress', progressData.progress)
            // console.log(JSON.stringify(progressData))
            callback(progressData)
          }
        }, function (err, data) {
          // console.log(err || data)
          if (data) {
            data.uploadFinish = true
            data.key = key
            data.keyUUID = uuid
            data.filename = file.name
            // console.log(JSON.stringify(data))
            callback(data)
          }
          if (err) {
            if (error && typeof error === 'function') error(err)
          }
        })
      }
    }).catch(err => {
      if (error && typeof error === 'function') error(err)
      // console.log(err)
    })
  },
  // 亚马逊上传 get sts_info type=2
  async awsUpload (options = {}) {
    const {
      projectId,
      keyUUID,
      file,
      extension,
      isDir = 0,
      uploadModule,
      callback
    } = options
    const key = Utils.getStorageKeyPath(file.name, extension) + keyUUID + '.' + extension
    const requestParams = {
      type: Enum.ossType.amazon,
      extension: extension,
      isNeedCallback: 0, // 亚马逊上传  没有回调
      uploadModule: uploadModule,
      isDir: isDir,
      projectId: projectId
    }
    const response = await api.resource.sts_info2(requestParams)
    console.log('awsUpload response:', response)
    let s3 = new AWS.S3({
      accessKeyId: response.accessKeyId,
      secretAccessKey: response.secretAccessKey,
      sessionToken: response.securityToken,
      region: response.region || 'us-west-1'
    })
    let params = {
      Bucket: response.bucket || 'bsedit',
      Key: key,
      Body: file,
      ACL: 'public-read'
    }
    s3.upload(params, (err, sendData) => {
      console.log(err || sendData)
      if (sendData) {
        sendData.uploadFinish = true
        sendData.key = key
        sendData.keyUUID = keyUUID
        sendData.progress = 100
        sendData.filename = file.name
        const host = Utils.getCOSHost().replace('https://' || 'http://', '') + '/' // cdn host
        sendData.Location = sendData.Location.replace('https://' || 'http://', '').replace(/^[^/]*\//, host) // 替换成cdn host
        console.log(JSON.stringify(sendData))
        callback(sendData)
      }
    }).on('httpUploadProgress', function (data) {
      data.keyUUID = keyUUID
      data.uploadFinish = false
      data.progress = Math.round((data.loaded * 100) / data.total)
      data.key = key
      data.filename = file.name
      data.projectId = response.projectId,
      console.log('AWS progress', data.progress)
      console.log(JSON.stringify(data))
      callback(data)
    })
  },
  // 七牛云上传 get sts_info type=0 开发文档：https://developer.qiniu.com/kodo/1283/javascript#usage
  async qiniuUpload (options = {}) {
    const {
      title,
      projectId,
      keyUUID,
      file,
      extension,
      isDir = 0,
      isMXF = 0,
      uploadModule,
      callback,
      errorCb,
      uuid
    } = options
    try {
      const requestParams = {
        type: Enum.ossType.qiniu,
        extension,
        isNeedCallback: 0,
        uploadModule,
        isDir
      }
      if (uploadModule === Enum.uploadModule.bsProject) {
        requestParams.projectId = projectId
      }
      if (uploadModule === Enum.uploadModule.template) {
        requestParams.uuid = uuid
      }
      const response = await api.resource.sts_info2(requestParams)
      try {
        let finishedAttr = [];
        let compareChunks = [];
        // 设置next,error,complete对应的操作，分别处理相应的进度信息，错误信息，以及完成后的操作
        // let errorCb = function (err) {
        //     console.log("errorCb", err)
        //     error(err)
        // }
        const key = response.relativePath
        const token = response.securityToken
        const putExtra = {
          fname: file.name, // 文件原始文件名，若未指定，则魔法变量中无法使用 fname、ext、suffix
          // customVars: {}, // 用来放置自定义变量，变量名必须以 x: 开始，自定义变量格式及说明请参考文档
          // metadata: {}, // 用来防止自定义 meta，变量名必须以 x-qn-meta-开始，自定义资源信息格式及说明请参考
          // mimeType: '' // 指定所传的文件类型
        }
        const config = {
          useCdnDomain: true, // 表示是否使用 cdn 加速域名，为布尔值，true 表示使用，默认为 false。
          region: qiniu.region.z0, // 选择上传域名区域；当为 null 或 undefined 时，自动分析上传域名区域。
          retryCount: 6, // 上传自动重试次数（整体重试次数，而不是某个分片的重试次数）；默认 3 次（即上传失败后最多重试两次）。
          // disableStatisticsReport: false, // 是否禁用日志报告，为布尔值，默认为 false。
          // checkByMD5: false, // 是否开启 MD5 校验
          // forceDirect: false, // 是否上传全部采用直传方式，为布尔值；为 true 时则上传方式全部为直传 form 方式，禁用断点续传，默认 false
          // concurrentRequestLimit: 3, //  分片上传的并发请求量，number，默认为3；因为浏览器本身也会限制最大并发量，所以最大并发量与浏览器有关
          // chunkSize: 5, // 分片上传时每片的大小，必须为正整数，单位为 MB，且最大不能超过 1024，默认值 4。
          // debugLogLevel: 'INFO'
        }
        // 调用sdk上传接口获得相应的observable，控制上传和暂停
        const observable = qiniu.upload(file, key, token, putExtra, config);
        // 接收上传进度信息的回调函数
        const next = function (response) {
          let chunks = response.chunks || [];
          let total = response.total;
          // 这里对每个chunk更新进度，并记录已经更新好的避免重复更新，同时对未开始更新的跳过
          for (let i = 0; i < chunks.length; i++) {
            if (chunks[i].percent === 0 || finishedAttr[i]) {
              continue;
            }
            if (compareChunks[i].percent === chunks[i].percent) {
              continue;
            }
            if (chunks[i].percent === 100) {
              finishedAttr[i] = true;
            }
          }
          // console.log("qiniuUpload progress", total.percent)
          compareChunks = chunks;
          let progressData = {}
          progressData.keyUUID = keyUUID
          progressData.resourceId = response.objectId
          progressData.progress = Math.round(total.percent)
          progressData.key = key
          progressData.filename = file.name
          progressData.uploadFinish = false
          progressData.Location = Utils.getCOSHost() + '/' + key
          progressData.Location = progressData.Location.replace(/https:\/\/|http:\/\//, '')
          callback(progressData)
        }
        // 接收上传完成后的后端返回信息
        const complete = function (res) {
          // console.log("complete ", res)
          let progressData = {}
          progressData.keyUUID = keyUUID
          progressData.resourceId = response.objectId
          progressData.progress = 100
          progressData.key = key
          progressData.filename = file.name
          progressData.uploadFinish = true
          progressData.Location = Utils.getCOSHost() + '/' + key
          progressData.Location = progressData.Location.replace(/https:\/\/|http:\/\//, '')
          callback(progressData)
        }
        const subObject = {
          next: next,
          error: errorCb,
          complete: complete
        };
        observable.subscribe(subObject) // 上传开始
      } catch (e) {
        console.log(e)
        // 捕获超时异常。
        if (e.code === 'ConnectionTimeoutError') {
          console.log('TimeoutError')
          // do ConnectionTimeoutError operation
        }
        if (errorCb && typeof errorCb === 'function') {
          console.error('分片上传失败:', e.code)
          errorCb(e)
        }
      }
    } catch (message) {
      if (errorCb && typeof errorCb === 'function') errorCb(message)
    }
  },
  // 华为云上传
  async huaweiUpload (options = {}) {
    const {projectId, keyUUID, file, extension, isDir = 0, uploadModule, callback} = options
    const requestParams = {
      type: Enum.ossType.huawei,
      extension: extension,
      isNeedCallback: 0,
      uploadModule: uploadModule,
      isDir: isDir,
      projectId: projectId
    }
    const response = await api.resource.sts_info2(requestParams)
    // 创建ObsClient实例
    const obsClient = new ObsClient({
      access_key_id: response.accessKeyId,
      secret_access_key: response.secretAccessKey,
      security_token: response.securityToken,
      server: response.endpoint
    })
    let xmlFile
    // 处理xml文件
    if (file instanceof Blob) {
      xmlFile = file
    } else {
      const blob = new Blob([file], { type: 'text/plain' })
      xmlFile = new File(
        [blob],
        response.relativePath.replace(/^.*\/([^\/]+)$/, '$1')
      )
    }
    let cp
    let hook
    let progress
    let finish = false // 记录上传完成的状态
    obsClient.uploadFile({
      Bucket: response.bucket,
      Key: response.relativePath,
      SourceFile: xmlFile,
      PartSize: 9 * 1024 * 1024,
      ProgressCallback: (transferredAmount, totalAmount, totalSeconds) => {
        // console.log(Math.round(transferredAmount * 1.0 / totalSeconds / 1024), Math.round(transferredAmount * 100.0 / totalAmount));
        progress = Math.round(transferredAmount * 100.0 / totalAmount)
        if (hook && (transferredAmount / totalAmount) > 0.5) {
          // 暂停断点续传任务
          hook.cancel();
        }
        let progressData = {}
        progressData.keyUUID = keyUUID
        progressData.progress = progress
        progressData.filename = file.name
        progressData.resourceId = response.objectId
        progressData.key = response.relativePath
        progressData.uploadFinish = false
        progressData.Location = Utils.getCOSHost({ isOrigin: response.relativePath.endsWith('.xml') }) + '/' + response.relativePath.replace(/(dir\/)\*$/, '$1' + file.name)
        progressData.Location = progressData.Location.replace(/https:\/\/|http:\/\//, '')
        if (!finish) callback(progressData)
      },
      EventCallback: (eventType, eventParam, eventResult) => {
        // 处理事件响应
      },
      ResumeCallback: (resumeHook, uploadCheckpoint) => {
        // 获取取消断点续传上传任务控制参数
        hook = resumeHook
        // 记录断点
        cp = uploadCheckpoint
      }
    }, (err, result) => {
      // console.error('Error-->' + err);
      // 出现错误，再次调用断点续传接口，继续上传任务
      if (err) {
        obsClient.uploadFile({
          UploadCheckpoint: cp,
          ProgressCallback: (transferredAmount, totalAmount, totalSeconds) => {
            // console.log(Math.round(transferredAmount * 1.0 / totalSeconds / 1024), Math.round(transferredAmount * 100.0 / totalAmount));
            progress = Math.round(transferredAmount * 100.0 / totalAmount) <= progress ? progress : Math.round(transferredAmount * 100.0 / totalAmount)
            let progressData = {}
            progressData.keyUUID = keyUUID
            progressData.progress = progress
            progressData.filename = file.name
            progressData.resourceId = response.objectId
            progressData.key = response.relativePath
            progressData.uploadFinish = false
            progressData.Location = Utils.getCOSHost({ isOrigin: response.relativePath.endsWith('.xml') }) + '/' + response.relativePath.replace(/(dir\/)\*$/, '$1' + file.name)
            progressData.Location = progressData.Location.replace(/https:\/\/|http:\/\//, '')
            if (!finish) callback(progressData)
          },
          EventCallback: (eventType, eventParam, eventResult) => {
            // 处理事件响应
          }
        }, (err, result) => {
          if (err) {
            console.error('Error-->' + err);
          } else {
            // console.log("result", result)
            if (result.CommonMsg.Status < 300 && result.InterfaceResult) {
              finish = true
              let progressData = {}
              progressData.keyUUID = keyUUID
              progressData.progress = 100
              progressData.filename = file.name
              progressData.resourceId = response.objectId
              progressData.key = response.relativePath
              progressData.uploadFinish = true
              progressData.Location = Utils.getCOSHost({ isOrigin: response.relativePath.endsWith('.xml') }) + '/' + response.relativePath.replace(/(dir\/)\*$/, '$1' + file.name)
              progressData.Location = progressData.Location.replace(/https:\/\/|http:\/\//, '')
              callback(progressData)
            }
          }
        })
      } else {
        // console.log('Status-->' + result.CommonMsg.Status);
        if (result.CommonMsg.Status < 300 && result.InterfaceResult) {
          // console.log('RequestId-->' + result.InterfaceResult.RequestId)
          finish = true
          let progressData = {}
          progressData.keyUUID = keyUUID
          progressData.progress = 100
          progressData.filename = file.name
          progressData.resourceId = response.objectId
          progressData.key = response.relativePath
          progressData.uploadFinish = true
          progressData.Location = Utils.getCOSHost({ isOrigin: response.relativePath.endsWith('.xml') }) + '/' + response.relativePath.replace(/(dir\/)\*$/, '$1' + file.name)
          progressData.Location = progressData.Location.replace(/https:\/\/|http:\/\//, '')
          callback(progressData)
        }
      }
    })
  },

  // 金山云 上传
  async ksyunUpload(options = {}){
    let {
      title,
      projectId,
      keyUUID,
      file,
      extension,
      isDir = 0,
      isMXF = 0,
      uploadModule,
      callback,
      errorCb,
      uuid
    } = options
    let fileOrBlob = file
    if (typeof file === 'string') {
      fileOrBlob = new Blob([file], {type: 'text/plain;charset=utf-8'})
    }
    options.file = fileOrBlob

    try {
      const requestParams = {
        type: Enum.ossType.ksyun,
        extension,
        isNeedCallback: 0,
        uploadModule,
        isDir
      }
      if (uploadModule === Enum.uploadModule.bsProject) {
        requestParams.projectId = projectId
      }
      if (uploadModule === Enum.uploadModule.template) {
        requestParams.uuid = uuid
      }
      const response = await api.resource.sts_info2(requestParams)
      const isMultipartUpload = fileOrBlob.size > 1024 * 1024 * 10  // 大于 10M  使用 分块上传

      if(isDir || isMXF){
        const promiseQueue = []
        Array.from(file).forEach((fileOne, index) => {
          const res = cloneDeep(response)
          res.relativePath =  res.relativePath.replace(/(dir\/)\*$/, '$1' + fileOne.name)
          promiseQueue.push(ksyunDirectUploadSdk({
            keyUUID, file: fileOne, callback, errorCb, isOneUpload: false, fileCount: file.length }, res))
        })
        await allWithProgress(promiseQueue, function (index) {
          vm.$bus.$emit(vm.$EventBusKey.TXUpload, JSON.stringify({
            title, keyUUID, progress: Math.round(index / (file.length - 1) * 100), uploadType: 0
          }))
        })
        callback({
          keyUUID,
          progress: 100,
          filename: file[0].name,
          key: response.relativePath.replace(/(dir\/)\*$/, '$1' + file[0].name),
          resourceId: response.objectId,
          Location: (Utils.getCOSHost()  + '/' + response.relativePath.replace(/(dir\/)\*$/, '$1' + file[0].name)).replace(/https:\/\/|http:\/\//, ''),
          uploadFinish: true
        })
        return
      }
      if (isMultipartUpload){
        multipartUpload(options, response)
      } else {
        ksyunDirectUploadSdk(options, response)
      }
    }catch (e) {
      console.error(e)
    }
  },

  // 闻歌上传
  async wgUpload(options = {}) {
    let {
      keyUUID,
      file,
      callback,
      errorCb: error,
      isDir,
      extension,
      isMXF,
      uuid
    } = options

    if(isDir || isMXF){
      alert("闻歌上传，暂时不支持文件或序列化上传")
      return
    }
    let fileName = file.name

    if (typeof file === 'string') {
      file = new Blob([file], {type: 'application/octet-stream'})
    }
    if (file instanceof Blob){ // 设置文件名称和后缀
      if (extension === 'template') { // 上传模版文件事文件名用uuid，因为uuid 与模版的ID对应
        fileName = uuid + "." + extension
      } else {
        fileName = keyUUID + "." + extension
      }
    }
    // 普通上传
    const isMultipartUpload = file.size > 1024 * 1024 * 10  // 大于 10M  使用 分块上传
    if (!isMultipartUpload) {
      let formData = new FormData()
      formData.append('files', file, fileName)
      const res = await axios({
        url: Utils.getCOSHost() + '/api/file/getUrl',
        method: 'post',
        data: formData
      })
      if (callback && typeof callback === 'function') {
        const params = {
          keyUUID,
          progress: 100,
          filename: fileName,
          resourceId: '',
          key: '',
          uploadFinish: true,
          Location: res.data.data[0] ? res.data.data[0].replace(/^http:\/\/|https:\/\//, '') : ''
        }
        callback(params)
      }
      return
    }

    // 分片上传
    const uploader = new wgBaseUpload(file, {
      url: Utils.getCOSHost(),
      uuid: keyUUID, key: '', error,
      progress: callback,
      itemSize: 1024 * 1024 * 5, // 分片中每片大小
      userId: localStorage.nvseditloginuserid,
      tenantId: localStorage.nvseditlogintenantid || 1
    })
    console.log(uploader)
  },

  downloadM3U8UrlToLocal (url, callback) {
    axios.get(url)
      .then(function (response) {
        let fileName = Utils.getNameFromUrl(url)
        let localFilePath = '/m3u8/' + fileName
        FS.writeFile(localFilePath, response.data)
        callback(localFilePath)
      })
  }
}
