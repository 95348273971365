import axios from 'axios'
import { Message } from 'element-ui'
import store from '@/store'
import { getToken } from '@/utils/auth'
import i18n from '@/i18n'
import { getCheckSign } from '@/utils'
import vm from '@/main'
import { isMessageDuplicate } from '@/utils'

// create an axios instance
const service = axios.create({
  withCredentials: false, // send cookies when cross-domain requests
  // timeout: 5000 // request timeout
})

function getDomain(url) {
  const urlArr = url.split('/')
  if (urlArr[2]) {
    return urlArr[2]
  } else {
    return ''
  }
}

// request interceptor
service.interceptors.request.use(
  config => {
    if (process.env.CONFIG_NAME === 'smg') {
      const domain = getDomain(config.url)
      if (config.method === 'post') {
        config.data = {
          ...config.data,
          ...getCheckSign(domain)
        }
      } else if (config.method === 'get') {
        config.params = {
          ...config.params,
          ...getCheckSign(domain)
        }
      }
    }

    if (config.method === 'get' && config.params.noNeedAuth) {
      // 客户的环境下，material接口不需要在header里传Authorization
      delete config.params.noNeedAuth
    } else if (getToken()) {
      config.headers.Authorization = getToken()
    }

    if (config.version) {
      config.headers.version = config.version
    }

    if (config.version) {
      config.headers.version = config.version
    }

    return config
  },
  error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
   */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    const { code, message = '' } = response.data

    // if the custom code is not 0, it is judged as an error.
    if (code !== 0 && code !== 1) {
      const messageArr = message.split('---')
      let msg
      if (messageArr.length === 2) {
        if (i18n.locale === 'zh') {
          msg = messageArr[0]
        } else {
          msg = messageArr[1]
        }
      }
      // 如果code是3，表示没有权限
      if (code === 3) {
        msg = '上传提示：您的账号没有上传权限，如需开通权限，请联系商务'
      }
      if (!/\/resource\/append/.test(response.config.url)) {
        isMessageDuplicate({
          message: msg || 'Error'
        })
      }
      // 如果code是-2，表示token过期了
      if (code === -2) {
        store.dispatch('user/resetToken').then(() => {
          const queryObj = vm.$route.query
          if (!queryObj.token) {
            location.reload()
          }
        })
      }
      return Promise.reject(msg)
    } else {
      return response.data
    }
  },
  error => {
    console.log('err' + error) // for debug
    Message({
      message: error.message,
      type: 'error',
      duration: 5 * 1000
    })
    return Promise.reject(error)
  }
)

export const get = ({ url, headers }) => {
  return service.get(url, headers).then(res => {
    return res.data
  })
}

export const post = ({ url, data, headers, onUploadProgress }) => {
  return service.post(url, data, { headers, onUploadProgress }).then(res => {
    return res.data
  })
}

export const delet = ({ url, headers }) => {
  return service.delete(url, headers).then(res => {
    return res.data
  })
}
