import Global from "@/api/Global"

export default {
  namespaced: true,
  state: {
    rulerRule: 8
  },
  getters: {
    pixelPerMicrosecond (state) {
      const rulingSpace = Global.TimelineRules[state.rulerRule].rulingSpace
      const tipSpace = Global.TimelineRules[state.rulerRule].tipSpace
      return rulingSpace / tipSpace
    }
  },
  mutations: {
    updateRulerRule (state, payload) {
      state.rulerRule = payload
    }
  }
}