const zh = {
  enum: {
    materialType: {
      'all': '全部',
      'video': '视频',
      'audio': '音频',
      'picture': '图片',
      'folder': '文件夹'
    },
    mType: {
      theme: '主题',
      videofx: '滤镜',
      captionstyle: '字幕',
      animatedsticker: '贴纸',
      videotransition: '转场',
      font: '字体',
      music: '音乐',
      particle: '粒子',
      arscene: 'AR道具',
      compoundCaption: '组合字幕',
      photoAlbum: '影集',
      mimo: 'MIMO',
      vlog: '拍摄模板',
      template: '通用模版',
      animationVideofx: '滤镜动画',
      backgroundImage: '背景图片',
      animationCaption: '字幕动画'
    },
    backgroundFillType: {
      color: '颜色',
      image: '样式',
      blur: '模糊',
    },
    splitCategory: {
      clip: '片段',
      people: '人物',
      voice: '语音',
      word: '文字'
    },
    projectTag: {
      live: '直播剪辑',
      template: '通用模板',
      split: '视频拆条',
      textVideo: '文字视频',
      meiying: '美映',
      interact: '互通'
    },
    materialAuthType: {
      public: '公开',
      authed: '已购',
      private: '自制'
    },
    materialStatus: {
      progress: '进行中',
      failed: '未转码',
      success: '可用'
    },
    accountType: {
      'mainAccount': '主账号',
      'subAccount': '子账号'
    },
    mediaEditTab: {
      basic: '基础设置',
      speed: '速度',
      filter: '滤镜',
      effect: '特效',
      colorCorrection: '调节',
      audio: '音频',
      keyer: '抠像',
      animation: '动画',
      mask: '遮罩',
      background: '背景'
    },
    captionEditTab: {
      basic: '编辑字幕',
      renderer: '花字',
      context: '气泡',
      animation: '动画',
      align: "排列",
    },
    stickerEditTab: {
      basic: '编辑贴纸',
      animation: '动画'
    },
    curveSpeed: {
      giveupHint: '是否放弃曲线变速，使用常规变速',
      line: '常规变速',
      curve: '曲线变速',
      custom: '自定义',
      hero: '英雄时刻',
      bullet: '子弹时间',
      montage: '蒙太奇',
      jumper: '跳接',
      flashInto: '闪进',
      flash: '闪出',
    },
    textTool: {
      edit: '编辑',
      merge: '合并',
      split: '拆分',
      sticker: '贴纸',
      caption: '组合字幕',
      stand: '机位',
      template: '模板',
      delete: '删除',
      subsection: '分段'
    },
    liveTabName: {
      clipList: '片段列表',
      audioToText: '语转文结果'
    },
    captionFormat: {
      txt: 'txt文件',
      srt: 'srt文件',
      csv: 'csv文件'
    },
    coarseJobStatus: {
      waiting: '等待中',
      compileDoing: '合成中',
      compileDone: '合成完成',
      compileFail: '合成失败',
      transcodeDoing: '转码中',
      transcodeDone: '转码完成',
      transcodeFail: '转码失败'
    },
    letterSpacingType: {
      percentage: '百分比',
      absolute: '绝对值'
    },
    liveTabName: {
      clipList: '片段列表',
      audioToText: '语转文结果'
    },
    captionFormat: {
      txt: 'txt文件',
      srt: 'srt文件',
      csv: 'csv文件'
    },
  },
  'views': {
    'login': {
      'logo1': '智能视频生产平台',
      'logo2': '多端互动便捷新体验',
      'logo3': '',
      'username': '用户名',
      'password': '密码',
      'login': '登录',
      'register': '注册',
      'loginTitle': '登录',
      'registerTitle': '注册',
      'forgetPassword': '忘记密码',
      'loginButton': '登录',
      'registerButton': '注册',
      'hasNoAccount': '没有账号',
      'hasAlreadyAccount': '已有账号，去登录',
      'email': '邮箱',
      'phone': '手机号',
      'verifyCode': '验证码',
      'imageCode': '图形验证码',
      'getBackPassword': '找回密码',
      'nextStep': '下一步',
      'finishForget': '完成重设',
      'mailSentSuccess': '邮件发送成功',
      'ph_account': '请输入邮箱地址/手机号',
      'ph_password': '6-18位，包含数字、英文',
      'ph_email': '请输入邮箱地址',
      'ph_phone': '请输入手机号',
      'ph_imageCode': '输入图中字符',
      'ph_password_confirm': '请确认密码',
      'ph_verifyCode': '请输入验证码'
    }
  },
  'router': {
    'login': {
      'register': '注册账号',
      'getBackPassword': '找回密码'
    }
  },
  'valid': {
    'isRequired': '该项为必选',
    'notNull': '不能为空',
    'categoryNotNull': '素材类型不能为空',
    'minLength': '最小长度是',
    'maxLength': '最大长度是',
    'lengthIs': '长度必须是',
    'inputPasswordAgain': '请再次输入密码',
    'passwordNotSame': '密码不一致',
    'phoneSame': '新旧手机号不能一致',
    'emailSame': '新旧邮箱账号不能一致',
    'verifyCodeErr': '验证码错误',
    'notRightEmailPattern': '邮箱格式不正确',
    'notRightPhonePattern': '手机格式不正确',
    'notRightPasswordPattern': '密码必须包含数字，英文',
    'emailExist': '邮箱已存在',
    'phoneExist': '手机号已存在',
    'phoneNotRegister': '该手机号未注册',
    'emailNotRegister': '该邮箱未注册',
    'uploadBusinessLicense': '请上传营业执照',
    'iosAppIdNotNull': 'iOS系统应用ID 不能为空',
    'androidAppIdNotNull': '安卓系统应用ID 不能为空',
    'appIdNotNull': '小程序应用ID 不能为空',
    'atLeastOneAppId': '请填写至少一项应用ID',
    'atLeastOneVideoClip': '应用主题至少需要一个视频片段',
    'tagsIncluded': '标签已经包含',
    'tagsOver': '已达最大标签数',
    'tagsEmpty': '标签不能为空',
    'materialSizeNotRight': '素材大小不满足条件',
    'materialRatioNotRight': '不建议此素材分辨率',
    'packageNotUploaded': '请先上传素材包'
  },
  home: {
    projects: '智能工具',
    pieces: '我的成片',
    template: '模板中心',
    create: '视频创作',
    btnCreateNew: '新建项目',
    cardTitle: '云剪辑',
    point: '本系统目前仅用于产品功能演示，系统会定期自动清空个人用户项目及素材，默认存储时间为48小时，如有特殊需求请联系我们!',
    cardDes: '自由的制作原创视频',
    myProject: '我的项目',
    createTime: '创建时间',
    alterTime: '最近改动时间',
    normalTitle: '新建项目',
    searchHint: '输入项目名称进行搜索',
    deletes: '删除',
    download: '下载',
    publish: '发布',
    userManage: '管理中心',
    lastModified: '最后一次更新：',
    creativeTime: '创作时间：',
    selectAll: '全选(已选择{0}个素材)',
    selectSize: '选择项目尺寸',
    inputProName: '请输入项目名称',
    submit: '确认',
    splitHint1: '智能分析中...',
    splitHint2: '分析完成后可进行下一步操作',
    splitHint3: '智能分析完毕',
    splitHint4: '点击进入剪辑',
    splitHint5: '智能分析失败',
    splitHint6: '语音识别失败',
    interactHint: '转码中...',
    ratio: '画幅比例',
    projectName: '项目名称',
    title_deleteProject: '确认要删除{msg}？',
    ph_ratio: '选择画幅比例',
    ph_name: '输入项目名称',
    resolvingPower: '分辨率',
    effectsPlatform: '特效素材创作平台'
  },
  userLogin: {
    login: '登录',
    logout: '退出',
    register: '注册',
    registerSucceed: '注册成功,请登录',
    registerFailed: '注册失败',
    registerCodeError: '验证码不正确',
    alreadyRegistered: '该账号已经注册',
    phone: '请输入手机号',
    pwd: '请输入密码',
    affirm: '请再次确认密码',
    code: '输入验证码',
    getCode: '获取验证码',
    notAccount: '还没账号?',
    registerNow: '立即注册',
    forgotPassword: '忘记密码?',
    retrievePassword: '找回密码',
    resetPassword: '确定重置',
    updateUserException: '更新用户信息失败: 发生异常',
    updateUserPasswordEmpty: '更新用户信息失败: 密码为空',
    updateUserSuccess: '更新用户信息成功',
    registerVerifyCodeWrong: '验证码错误',
    userNotFound: '用户不存在',
    haveAccount: '已有账号?',
    loginNow: '立即登录',
    numberNotEmpty: '号码不能为空!',
    codeFailed: '获取验证码失败! 请重试',
    imgCodeFailed: '图片验证码错误',
    pwdNotAffirm: '密码不一致，请重新输入',
    registerSuccess: '您已注册成功！请登录',
    loginPasswordWrong: '登录失败: 密码错误',
    inputImageCode: '请先输入图片验证码'
  },
  audioContextTip: '需要打开音频播放',
  audioContextOk: '确定',
  titleBar: {
    bcutHint:'必剪模板制作请切换到2.0版本',
    version: 'V' + window.$nvsConfig.SDKVersion.replace(/_/g, '.'),
    title: '智能视频生产平台',
    cloudTitle: '美摄云剪辑',
    liveTitle: '美摄直播剪辑',
    historyVersion: '历史版本',
    currentVersion: '当前版本',
    isCurrent: '当前',
    updateNote: '更新内容',
    checkoutVersion: '切换版本',
    historyVersionDialog: {
      title: '历史版本',
      manualSave: '手动保存',
      autoSave: '自动保存',
      emptyText: '暂无保存历史',
      openLoading: '打开中...',
      open: '打开'
    },
    task: '任务',
    shortcuts: '快捷键',
    export: '导出视频',
    generate: '生成视频',
    refreshing: '刷新',
    back: '返回',
    backEditProject: '返回剪辑项目',
    upload: '上传',
    generalUpload: '普通上传',
    trimUpload: '快速截取（仅限视频）',
    record: '录音',
    save: '保存',
    saveAs: '另存为',
    exportProjectXml: '导出',
    saveInteractProjectFailed: '保存互通工程失败',
    exportPRXML: '导出XML',
    multipleChoice: '多选',
    multipleChoiceClose: '取消',
    multipleChoiceAddToTrack: '添加到轨道',
    trimFileSizeOutRange: '裁剪的文件不支持超过2G',
    triming: '正在裁剪文件...',
    selectSingleFile: '请一次选择一个文件进行裁剪',
    unsupportedFormat: '不支持该格式文件',
    videoNotSupported: '当前视频编码格式，无法预览'
  },
  featureBar: {
    media: '资源库',
    caption: '字幕',
    sticker: '贴纸',
    music: '音乐',
    theme: '主题',
    transition: '转场',
    filter: '滤镜',
    particle: '粒子',
    effect: '特效',
    mask: '蒙版',
    regulate: "调节",
    all: '全部',
    video: '视频',
    audio: '音频',
    image: '图片',
    AILibretto: 'AI唱词字幕',
    tag: '素材标签'
  },
  AILibretto: {
    languageRecognition: '语音识别',
    pleaseSelect: '请选择需要识别的视频或音频片段',
    identifying: '识别中',
    noAudio: '该片段没有声音',
    apply: '应用',
    applyToTimeline: '应用到时间线',
    cancel: '取消',
    preview: '预览',
    error: '识别失败: 未识别到字幕'
  },
  monitor: {
    speed: '倍速',
    particleTitle: '粒子运动轨迹设置',
    information: '鼠标左键在画面区域单击后开始自动记录粒子运动轨迹，再次单击后停止记录',
    commit: '确认',
    reset: '重置',
    selectedDuration: '选中时长',
    cutAndPreview: '剪辑预览',
    audioPreview: '音频预览',
    imagePreview: '图片预览',
    scaleMark: '刻度线',
    confirm: '导入上轨',
    cancel: '取消',
    setEntryPoint: '设为入点',
    setPointOut: '设为出点',
    preview: '区间播放',
    backEntryPoint: '回到入点',
    backPointOut: '回到出点',
    revoke: '撤销',
    generateClip: '生成片段',
    videoTag: {
      videoTag: '视频标签',
      objectTag: '物体标签',
      peopleTag: '人物标签',
      envTag: '环境标签',
      imageDef: '影像定义',
      specialDef: '特殊定义',
      matchObjects: '共匹配到{msg}个物体',
      noDataHint: '未对标签内容进行识别',
      startReco: '开始识别',
      imageRate: '出场率',
      tagRate: '出现率',
      imageConf: '相似度',
      tagConf: '置信度',
      faceNumber: '人脸数量',
      recoType: '识别类型',
      confirmReco: '确认',
      audioReco: '语音识别',
      videoReco: '视频识别',
      recoFail: '识别失败',
      recoDoing: '识别中'
    }
  },
  vumeter: {
    volume: '音频'
  },
  mediaResource: {
    hint1: '点击上传',
    hint2: '请上传需要剪辑的视频或音频文件',
    multiSelectHint: '当前处于多选状态，请取消后执行后续操作',
    btnCreateFolder: '新建文件夹',
    btnMultiSelect: '批量管理',
    mediaItemTextCut: '切割',
    mediaItemTextPreview: '预览',
    mediaItemTextNotTranscode: '未转码',
    sort: {
      updateTime: '更新时间',
      transcodeStatus: '转码状态',
      asc: '升序',
      desc: '降序',
    },
    table: {
      filename: '文件名',
      type: '类型',
      format: '格式',
      size: '大小',
      updateTime: '更新时间',
      duration: '时长',
      status: '状态',
      operation: '操作'
    },
    toCoverTip: '切换到缩略图模式',
    toDetailTip: '切换到列表模式',
    mediaPro: '项目资源',
    mediaMy: '我的资源',
    mediaPublic: '公共资源',
    split: '智能拆条'
  },
  mediaEditPanel: {
    AddPoint: '增加点',
    DeletePoint: '删除点',
    Crop: '裁剪',
    BasicSettings: '基本设置',
    CaptionStyle: '字幕样式',
    tags: '贴纸',
    GroupCaptionSettings: '组合字幕设置',
    GroupCaption: '组合字幕',
    Filter: '滤镜',
    ColorCorrection: '校色',
    Audio: '音频',
    Zoom: '缩放',
    Mask: '遮罩',
    Position: '位置',
    Speed: '速度',
    Transparency: '透明度',
    BackgroundModule: '背景模糊',
    Reset: '重置全部',
    Resetkx: '重置抠像',
    RotationAdjustment: '旋转',
    Overturn: '翻转',
    IntelligentColourCorrection: '智能校色',
    IntelligentAudioDenoise: '音频降噪',
    on: '开',
    off: '关',
    Exposure: '曝光',
    Highlight: '高光',
    Shadow: '阴影',
    Brightness: '亮度',
    Contrast: '对比度',
    Blackpoint: '褪色',
    Saturation: '饱和度',
    Vibrance: '自然饱和度',
    Tint: '色调',
    Temperature: '色温',
    Vignetting: '暗角',
    Degree: "暗角",
    Sharpness: '锐化',
    Amount: '锐化',
    Definition: '清晰度',
    Denoise: '噪点去除',
    VolumeControl: '音量大小',
    FadeIn: '淡入',
    FadeOut: '淡出',
    denoise: '音频降噪',
    second: '秒',
    original: '原图',
    cartoon: '漫画书',
    inkPainting: '水墨',
    monochrome: '单色',
    pen: '钢笔',
    spill: '溢色去除',
    tip: '在监视器上选中要抠像的区域',
    tip2: '请先在纯色背景上抠图后上传资料内容',
    keying: '抠像',
    changeSpeed: '动画自适应',
    animation: '动画',
    region: '区域',
    blendingMode: '混合模式',
    blendingModeOption : {
      normal: '正常',
      multiply: '正片叠加',
      subtract: '减去',
      screen: '滤色',
      add: '线性减淡',
      exclusion: '排除',
      darken: '变暗',
      burn: '颜色加深',
      linearburn: '线性加深',
      lighten: '变亮',
      dodge: '颜色减淡',
      overlay: '叠加',
      softlight: '柔光',
      hardlight: '强光',
      vividlight: '亮光',
      pinlight: '点光',
      linearlight: '线性光',
      hardmix: '实色混合',
      difference: '差值'
    },
    uploadFontFamily: '+上传字体包',
    transformMode: '变换模式',
    transformModeOption: {
      envelope: '信封',
      crop: '裁剪',
      stretch: '拉伸'
    },
    timesTheSpeed: '倍速'
  },
  captionEditPanel: {
    Hint: '请输入字幕内容',
    BlockPosition: '方位',
    applyStyle: '应用样式',
    Content: '内容',
    BouncingCaptions: '拍字幕',
    Style: '样式',
    EnterManually: '手动输入',
    VoicetoCaptions: '语音转字幕',
    ImportSRTCaptions: '导入SRT字幕',
    enterOrCopy: '请在此输入或粘贴文本',
    textEnterLine: '*请在文本中通过 “回车” 键进行换行',
    blankTimeSKey: '请通过键盘"空格"键确定字幕条起止时间，键盘"S"为播放快捷键',
    AddaLine: '加一行',
    EnterBlankLine: '插入空行',
    AutomaticTextWrap: '自动分行',
    Cancel: '取消',
    Clear: '清空',
    colorClear: '清空',
    colorConfirm: '确定',
    NextStep: '下一步',
    Revoke: '撤销',
    Finish: '完成',
    TextColor: '文字颜色',
    TextFont: '字体',
    caption: "字幕",
    Alignment: '对齐',
    alignWay: "对齐方式",
    letterSpacing: "字间距",
    lineSpacing: "行间距",
    coordinate: "位置",
    rotation: "旋转",
    scale: "缩放",
    shadow: "阴影",
    color: "颜色",
    feather: "羽化",
    offset: "偏移",
    outline: "描边",
    alpha: "不透明度",
    background: "背景",
    radius: "圆角",
    Stroke: '描边',
    Width: '宽度',
    Position: '位置',
    BottomClearance: '底部间距',
    testBouncingCaptions: '示例字幕',
    CombinationSubtitles: '组合字幕',
    selects: '请选择',
    MoreColor: '更多颜色...',
    richtextTip: '监测到内容变化，点击同步按钮同步到后台',
    richtextTab: '富文本编辑',
    ph_RichText: '请输入文字内容，回车换行',
    tip: '提示',
    cover: '覆盖',
    insert: '插入',
    tipMessage: '系统检测到当前插入位置已有片段',
    tipMessage2: '字体后缀名不符合规则',
    tipMessage3: '字体上传成功',
    tipMessage4: '字体上传失败，请联系管理员',
    defaultText: '默认文本'
  },
  stickerEditPanel: {
    PositionAdjustment: '位置',
    RotationAdjustment: '旋转'
  },
  musicEditPanel: {
    AudioEditor: '音频编辑',
    AudioSpecialEffects: '音频特效',
    VolumeControl: '音量',
    FadeIn: '淡入',
    FadeOut: '淡出',
    None: '原生',
    FastCartoonVoice: '礼堂',
    AudioReverb: '混响',
    AudioWahwah: '电子',
    FemaleVoice: '女生',
    MaleVoice: '男声',
    CartoonVoice: '卡通',
    AudioEcho: '回声',
    MonsterVoice: '怪兽'
  },
  themePanel: {
    CaptionsStyle: '编辑主题',
    TitleCaptions: '片头字幕',
    Font: '字体和大小',
    FontColor: '文字颜色',
    trailerCaptions: '片尾字幕',
    changeCaptions: '修改你的字幕',
    ThemeVolume: '主题音量'
  },
  transitionEditPanel: {
    Duration: '时长',
    durationSet: '时长设置',
    applyAll: '应用全部'
  },
  filterEditPanel: {
    ParameterAdjustment: '参数调整',
    Strength: '强度'
  },
  particleEditPanel: {
    ParameterAdjustment: '参数调整',
    Quantity: '数量',
    Size: '大小',
    MotionTrackingSettings: '运动轨迹设置'
  },
  regulateEditPanel: {
    title: "自定义调节",
    regulate: "调节",
    regulateEditor: '调节',
    editorArgs: {
      Temperature : '色温',
      Tint: '色调',
      Saturation: '饱和度',
      Brightness:  '亮度',
      Contrast: '对比度',
      Highlight: '高光',
      Shadow: '阴影',
      Blackpoint: '褪色',
      Amount:'锐化',
      Vignetting: '暗角'
    }
  },
  effectEditPanel: {
    Shape: '遮罩形状',
    Reverse: '反向',
    ellipse: '椭圆',
    rect: '方形',
    EffectEditor: '特效编辑',
    Blurry: '模糊',
    Mosaic: '马赛克',
    Mask: '遮罩',
    PositionAdjustment: '位置',
    Strength: '强度',
    Feather: '边缘羽化',
    Direction: '方位',
    Size: '大小',
    Width: '宽',
    Height: '高',
    KeyFrame: '关键帧',
    Reset: '重置全部'
  },
  compound: {
    videoDuration: '完整时长',
    videoFileSize: '视频文件大小',
    outputEffects: '输出效果',
    scene: '使用场景',
    low: '低',
    mid: '中',
    high: '高',
    scanMode: '扫描模式',
    fps: '帧率',
    outputType: '格式',
    interlace: '隔行扫描',
    progressive: '逐行扫描',
    encoderType: '编码',
    videoBitrateGrade: '码率',
    sizeLevel: '分辨率',
    newMedia: '新媒体/互联网',
    broadcast: '电视播出',
    teletorium: '演播室播出',
    compileVideo: '视频生成',
    compileGif: 'GIF生成',
    name: '名称',
    nameHint: '请输入名称',
    chineseNameHint: '请输入中文名称',
    englishNameHint: '请输入英文名称',
    noCaption: '无字幕',
    watermark: '水印',
    outputSet: '输出设置',
    PureAudio: '纯音频',
    issuePlatform: '发布平台',
    issueDes: '绑定以下平台账号，可一键全平台发布',
    preview: '合成预览',
    cover: '封面',
    getCover: '获取封面',
    localImage: '本地图片',
    startTime: '开始时间',
    spaceTime: '持续时间',
    spaceMaxTime: '最长支持截取15s',
    selectSize: '选择尺寸',
    fluency: '流畅度',
    fluencyFps: '帧 / 秒',
    commit: '确认合成',
    cancel: '取消',
    content: '简介',
    tag: '标签',
    contentMsg: '描述越准确，越利于触达兴趣人群'
  },
  task: {
    upload: '上传',
    transcode: '转码',
    compile: '合成',
    uploadFinish: '上传完成',
    compileFinish: '合成完成',
    uploading: '上传中',
    compiling: '合成中',
    transcodeing: '转码中',
    delete: '移除',
    uploadFailed: '上传失败',
    compileFailed: '合成失败',
    transcodeFailed: '转码失败',
    noTask: '没有任务',
    ai: '识别',
    aiRunning: '识别中',
    failed: '识别失败',
    canceled: '操作取消',
    aiFinish: '识别完成',
    see: '查看'
  },
  key: {
    previewPause: '预览/暂停',
    delete: '删除',
    cut: '切割',
    forwardBackward1: '向前/向后（逐帧）',
    forwardBackward2: '向前/向后（片段首位）',
    jumpStart: '跳到开始',
    jumpEnd: '跳到结束',
    rewindPlayback: '快退播放',
    fastPlayback: '快进播放',
    setInPoint: '设置入点',
    setOutPoint: '设置出点',
    shrinkTimeline: '时间轴缩小',
    expandTimeline: '时间轴放大',
    revoke: '撤销',
    save: '保存',
    insert: '插入上轨',
    mouseLeft: '鼠标左键',
    selectAll: '全选',
    forwardOneFrame: '前进一帧',
    backOneFrame: '后退一帧',
    screenCut: '画面裁剪'
  },
  browser: {
    equipmentTitle: '您的系统暂不支持，请在桌面系统下使用谷歌浏览器',
    equipmentSubTitle: '支持的系统：',
    Windows: 'Windows',
    Mac: 'macOS',
    Linux: 'Linux',
    browserTitle: '暂不支持当前浏览器，推荐使用以下浏览器的最新版本',
    browserSubTitle: '支持的浏览器：',
    Chrome: '谷歌',
    ChromeSub: 'Google',
    Firefox: '火狐',
    FirefoxSub: 'Firefox',
    Safari: 'Safari',
    SafariSub: 'Safari',
    _360: '360极速版',
    _360Sub: '360极速版',
    _360Cut: ',并切换到极速模式下',
    Opera: '欧朋',
    browser: ' 浏览器',
    OperaSub: 'Opera',
    Edge: '微软Edge',
    EdgeSub: 'Edge',
    updateTitle: '当前浏览器版本不支持,请升级到最新的 ',
    updateSubTitle: '带来更多安全、极速和乐趣。',
    recommendChrome: '推荐您使用最新版本的谷歌浏览器',
    recommendNo: '如果您暂时不想使用推荐的谷歌浏览器',
    paramsTitle: '您当前使用的浏览器需要设置以下选项才可工作，请根据浏览器版本按下面的步骤设置，完成后刷新页面',
    noSupport: '不支持',
    updateModeTitle: "隐私模式下不支持"
  },
  do: {
    revoke: '撤销',
    recover: '恢复',
    cut: '切割',
    delete: '删除',
    freezeframe: '定格视频',
    AudioVideoSeparation: '音视频分离',
    resumeAudioVideoSeparation: '还原音频',
    monoSeparation: '单声道分离',
    videoTrack: '视频轨道',
    audioTrack: '音频轨道',
    captionTrack: '字幕轨道',
    compoundCaptionTrack: '组合字幕轨道',
    stickerTrack: '贴纸轨道',
    musicLyricsTrack: '唱词轨道',
    timelineVideoFxTrack: '特效轨道',
    Mute: '静音',
    Volume: '音量',
    Show: '显示',
    NoShow: '不显示',
    AddSubtitle: '添加唱词',
    addTrack: '添加轨道',
    editTheme: '编辑主题',
    audioToText: 'AI唱词字幕',
    alignment: '视频同步',
    play: '播放',
    pause: '暂停',
    enableSound: '解除静音',
    showScreen: '全局显示',
    imageGrab: '截取',
    imageGrabMsg: '图片已截取，请在【我的成片】中查看',
    showSection: '区间显示',
    reverse: '倒放',
    cancelReverse: '取消倒放',
    copy: '复制',
    createCombination: '创建组合',
    removeCombination: '解除组合',
    screenCut: '画面裁剪',
    replaceClip: '替换片段'
  },
  liveEditor: {
    soundChannel: {
      stereo: '立体声',
      mono: '单声道',
    },
    dotDuration: "片段时长",
    videoPreview: '视频预览',
    bjTime: '北京时间',
    generateClip: '生成片段',
    liveSignal: '直播信号',
    deleteClip: '删除片段',
    carefulEdit: '精细剪辑',
    clipList: '片段列表',
    createLiveEditor: '新建直播剪辑',
    liveUrl: '直播地址',
    resolvingPower: '分辨率',
    selectPower: '请选择分辨率',
    liveEdit: '直播剪辑',
    liveUrlMsg: '请输入直播地址',
    des: '录制直播画面，即时拆剪精彩片段',
    sorketError: '服务连接失败, 无法获取更新',
    reconnection: '重新连接',
    connecting: '正在与服务器建立连接',
    connected: '服务器连接成功',
    publish: '发布',
    publishSuccess: '发布成功',
    stopUpdating: '停止更新',
    recoverUpdating: '恢复更新',
    stopMsg: '操作成功',
    stopMsgErr: '操作失败',
    published: '已发布',
    duration: '片段时长',
    ph_clip_search: '搜索',
    time: '时间',
    text: '文本',
    downloadCaption: '下载字幕',
    captionFormat: '字幕格式',
    msgStartTimeNotRight: '开始时间不正确',
    fineCutVideo: '精剪视频'
  },
  split: {
    splitEdit: '智能视频拆条',
    des: '一键自动拆条，支持手动调整',
    tag: '视频拆条',
    clipChangedItemDeleted: '片段已移动，相应记录已删除',
    ph_clip_search: '搜索片段名称',
    ph_onebest: '视频片段 ',
    create: {
      createSplitEditor: '创建智能拆条项目',
      selectMaterial: '选择素材',
      name: '项目名称',
      ph_name: '请输入项目名称',
      validName: '名称必填',
      sceneSelect: '选择拆条内容场景',
      clipSelect: '拆条内容来源选择',
      submit: '确认',
      cancel: '取消',
      tip1: '点击或将文件拖拽到这里上传',
      tip2: '支持扩展名：mp4、mov、avi、mpeg、wmv...',
      tip3: '素材中心选择',
      materialFromOnly: '拆条内容来源已选择',
      noMaterialSelect: '还未选择素材',
      closeHintWhenUploading: '还有素材在上传中，确定要关闭？',
      uploadingNotClose: '素材上传中，无法关闭'
    }
  },
  textVideo: {
    title: '智能文字视频剪辑',
    des: "通过文字编辑视频，打破传统方式",
    tag: "文字视频",
    clipChangedItemDeleted: "片段已移动，相应记录已删除",
    ph_clip_search: "搜索片段名称",
    ph_onebest: "视频片段 ",
    edit: {
      title: '修改内容',
      confirm: '确定',
      cancel: '取消',
      hint1: '只能修改单段内容'
    },
    create: {
      createTextVideoItem: "创建文字视频项目",
      selectMaterial: "选择素材",
      name: "项目名称",
      ph_name: "请输入项目名称",
      validName: "名称必填",
      sceneSelect: "选择拆条内容场景",
      clipSelect: "拆条内容来源选择",
      submit: "确认",
      cancel: "取消",
      audioNoiseReduction: "音频降噪",
      tip1: "点击或将文件拖拽到这里上传",
      tip2: "支持：mp4、mov、avi、mpeg等",
      tip3: "请上传单段多机位视频",
      tip4: "个人资源库",
      tip5: "公共资源库",
      materialFromOnly: "请删除已上传的本地文件",
      noMaterialSelect: "还未选择素材",
      closeHintWhenUploading: "还有素材在上传中，确定要关闭？",
      deleteUploading: "当前素材在上传中，请上传完成后再操作",
      removeFileHint: "确定移除",
      removeFileTitle: "提示",
      uploadingNotClose: "素材上传中，无法关闭",
      materialIsNull: '素材选择失败，请重试',
      // maxUploadNum: "最多上传三段多机位视频，请重新选择",
      maxUploadNum: "当前只支持上传一条视频，请重新选择",
      maxMaterialSelect: "最多只能选择三个多机位视频",
      uploadHead: {
        name: '文件名',
        progress: '上传进度',
        status: '状态',
        operation: '操作',
      },
      jobStatus: {
        doing: '上传中',
        done: '上传成功',
        fail: '上传失败'
      }
    },
    main: {
      logoTitle: '智能文字视频剪辑系统',
      impYunJian: '导入云剪',
      saveProject: '保存工程',
      impVideo: '导出视频',
    },
    clip: {
      headerTip: '上传图片或视频至片头，16：9的比例为佳',
      footerTip: '上传图片或视频至片尾，16：9的比例为佳'
    },
    template: {
      caption: [{
        title: '主标内容',
        font: '字体',
        color: '颜色'
      },
      {
        title: '副标内容',
        font: '字体',
        color: '颜色'
      }],
      upload: {
        uploadBtn: '上传资料',
        uploadFailBtn: '上传失败，请重试',
        replaceBtn: '替换',
        tip: '上传图片或视频至开窗中，16：9的比例为佳',
        volume: '素材音量'
      }
    },
    msg: {
      hint1: '不可删除最后一个视频段',
      hint2: '删除区域内包含已制作的模板，请删除模板后再试！',
      hasTemplate: '选区内包含已设置的模板，请重新选择！',
      invalid: '无效操作！',
      MXFFileLengthLimit: 'MXF文件最多上传9个! ',
    },
    projectName: '项目名称',
    textOverview: '文字总览',
    videoPreview: '视频预览',
    videoList: '视频列表',
    captionSetting: '组合字幕设置',
    librettoStyle: '唱词字幕设置',
    clipHeader: '上传片头',
    clipFooter: '上传片尾',
    head: '片头',
    foot: '片尾',
    mainStand: '主',
    other: '辅'
  },
  coarse: {
    coarseEdit: '粗剪',
    leftTopTitle: '视频编辑',
    tlWindowTitle: '源视频',
    monitorWindowTitle: '编辑视频预览',
    duration: '时长',
    clipList: '片段列表',
    deleteClip: '删除片段',
    previewClips: '预览选中的视频',
    saveSeparate: '逐条保存',
    saveWhole: '合并保存',
    saveSeperateError: '请先生成片段',
    saveWholeError: '请先选择片段',
    fineCutVideo: '精剪视频'
  },
  template: {
    title: '寻找你心仪的模板',
    msg: '海量设计模板, 覆盖百种设计场景',
    classify: '分类',
    screen: '场景',
    all: '全部',
    self: '个人',
    placeholder: '输入关键字搜索模板',
    search: '输入关键字搜索素材',
    searchTag: '请输入标签名称进行搜索',
    temp: '模板',
    audio: '音频',
    video: '视频',
    image: '图片',
    'video/image': '视频或者图片',
    edit: '云剪编辑',
    import: '导入',
    captionMsg: '输入文字, 回车修改字幕',
    short: '素材时长过短',
    exportTemplate: '导出模板',
    makeTemplate: '制作模板',
    makeTemplateHint: '模板包大小不能超过200MB，请压缩模板中的特效素材包及视频文件',
    templateDuration: '模板时长',
    templateSize: '模板大小',
    templateSizeHint: ' | 模板包大小不能超过200MB',
    templateSizeHint2: '计算中...',
    templateSizeHint3: '(预估)',
    templateCount: '片段数量',
    supportFrameRatio: '支持画幅',
    frameRatio: '画幅',
    canReplace: '可替换',
    generateMsg: '至少要有一个可替换的片段',
    fps: '帧数设置',
    preview: '预览视频',
    exactTag: '精准标签',
    selectClip: '请选择要替换的片段'
  },
  publish: {
    wechart: '微信视频号',
    weibo: '新浪微博',
    kuaishou: '快手',
    xinhua: '新华社',
    manage: '管理中心',
    noBindAccount1: '尚未绑定任何账号，请到',
    noBindAccount2: '绑定渠道',
    AccountBinding: '账户绑定',
    bind: '添加绑定',
    unbind: '解除绑定',
    status: '正常',
    selectPlatform: '选择发布平台',
    unbindMsg: '解除绑定后将不能使用该账户发布视频',
    accountManagement: '账号管理',
    dataManagement: '数据管理',
    viewCounts: '最近*天浏览量',
    likeCounts: '最近*天点赞量',
    forwardCounts: '最近*天转发量',
    commentCounts: '最近*天评论量',
    yesterday: '最近1天',
    nearly7: '最近7天',
    nearly14: '最近14天',
    nearly30: '最近30天',
    authSuccess: '授权成功',
    authFail: '授权失败',
    unbindSuccess: '成功解除绑定',
    data: '平台数据',
    user: '用户',
    num: '序号',
    platform: '平台',
    bindSuccess: '账号绑定成功',
    bindFailed: '账号绑定失败'
  },
  msg: {
    NameEmpty: '请输入名称',
    ImageEmpty: '请选择一个封面',
    delPieces: '您还未选择要删除的素材',
    saveSuccess: '保存成功',
    submitSuccess: '提交成功',
    saveFailed: '保存失败',
    saving: '保存...',
    compoundSuccess: '发布成功，可在任务中查看上传进度',
    invalidUrl: '无效的链接',
    loadModulesFailed: '加载失败',
    createFailed: '创建失败',
    streamFailed: '直播信号连接失败，请检查直播地址',
    readyJump: '准备跳转中...',
    addProject: '添加到项目素材',
    changeResolution: '切换画幅',
    copyMsg: '即将切换到一个空画幅, 是否复制',
    copyMsg2: '的时间线内容',
    copyResolution: '复制画幅',
    createResolution: '创建',
    isUploadList: '是否按照序列化文件上传',
    uploadList: '序列化上传',
    upload: '普通上传',
    captionMsg: '生成的字幕会覆盖原有字幕, 是否继续?',
    generateLibretto: '生成字幕',
    cover: '覆盖',
    alignMsg: '该操作会根据音频同步校准视频片段的位置, 是否继续?',
    deleteAllScaleKeyFrame: '是否删除该片段的所有缩放关键帧？',
    deleteAllTransKeyFrame: '是否删除该片段的所有位置关键帧？',
    deleteAllOpacityKeyFrame: '是否删除该片段的所有透明度关键帧？',
    deleteAllRotationKeyFrame: '是否删除该片段的所有旋转关键帧？',
    deleteAllMaskKeyFrame: '是否删除该片段的所有遮罩关键帧？',
    cutMsg: '在监视器上操作要裁剪的区域',
    replaceMsg: '请选择要替换的素材',
    addToTrack: '添加到轨道',
    deleteResource: '删除资源',
    retryResource: '重试',
    uploadFullMse: '无法上传，请前往',
    delete: '删除',
    msg: '提示',
    deleteMedia: '此操作将素材从项目列表中移除, 是否继续',
    addToTranscode: '开始转码',
    deleteResource: '删除资源',
    applySuccess: '应用成功',
    applyError: '应用失败',
    reverseSuccess: '倒放成功',
    reverseError: '倒放失败',
    reverseWaiting: '倒放中',
    replaceClipDurationError:'当前视频小于元片段时长，不支持替换',
    copyTip: '暂不支持多选复制, 请重新选择!',
    inOperation: '操作中'
  },
  vanchor: {
    title: '智能虚拟主播',
    des: '使用虚拟形象智能生成视频'
  },
  smartColorCorrection: {
    title: '智能校色',
    des: '一键专业级校色'
  },
  smartClip: {
    title: '智能剪辑',
    des: '一键Vlog成片'
  },
  audioRecord: {
    generate: '生成录音文件中',
    preview: '同时预览',
    mute: '预览静音'
  },
  smartRecording: {
    title: '智能录制',
    des: '美颜美型、AR特效、虚拟背景实时渲染',
    setting: "设置",
    createDialogForm: {
      title: '选择项目尺寸',
      ratio: "画幅比例",
      confirm: "确认",
      versionHint: '浏览器版本要求:   Chrome 94及其更高版本',
      versionHintMessage: '当前浏览器不支持，请查看浏览器版本要求'
    },
    controlMenuBar: {
      resolution: "分辨率",
      resolutionTip:'分辨率设置区间取决于当前摄像头硬件参数，系统最大支持1080P',
      resolutionPlaceholder: "选择分辨率",
      back: '返回',
      audioInputPlaceholder: "选择麦克风",
      videoInputPlaceholder: "选择摄像头",
      resetRecording: "重新录制",
      edit: "编辑",
      download: "下载",
      videoMirror: "视频镜像"
    },
    beauty: {
      title: '美颜',
      switchTitle: "效果开关",
    },
    filter: {
      title: "滤镜",
      none: "无滤镜",
    },
    bgBlur: {
      title: "虚拟背景",
      none: "无背景",
      bgBlur: "背景虚化"
    },
    ARScene: {
      title: "AR道具",
      none: "无道具"
    },
    testData: {
      frameTime: '每帧耗时',
      maxTime: '最大耗时',
      minTime: '最小耗时',
      packetLoss: '丢包'
    }

  },
  dialog: {
    finish: '完成',
    confirm: '确定',
    cancel: '取消',
    delPro: '是否删除该项目',
    delPieces: '是否删除该成片'
  },
  loading: {
    wasm: '加载编辑模块...',
    font: '安装字体...',
    importSrt: '导入Srt文件...',
    alignment: '视频对齐中...',
    loading: '加载中...',
    richCaption: '处理字幕中...',
    handling: '处理中',
    savingNoOtherOperation: '视频保存过程中，请勿关闭页面或进行其他操作',
    returnToEdit: '返回编辑',
    saveCompelete: '合成完成!',
    jumpPage: '保存',
    installing: '资源安装中...'
  },
  font: {
    SourceHanSansCN: '思源黑体 CN',
    font1: '字體1',
    font2: '字體2',
    font3: '字體3',
    font4: '字體4',
    font5: '字體5'
  },
  controlbar: {
    jumpStart: '跳到开头',
    jumpEnd: '跳到结尾',
    head5Frame: '向前5帧',
    tail5Frame: '向后5帧',
    safebox: '安全框'
  },
  labels: {
    delete: '删除',
    all: '全部',
    isUsed: '已选用',
    defaultFolderName: '新建文件夹',
    export: '导出',
    create: '创建',
    addKeyframe: '添加关键帧',
    waiting: '请稍后',
    missing: '丢失',
    comingSoon: '即将上线，敬请期待',
    noData: '暂无数据',
    loadingData: '加载中...',
    getVerifyCode: '获取验证码',
    hasSent: '已发送·',
    purchased: '已购',
    none: '无',
    edit: '编辑',
    checked: '已选中',
    applyStyle: '应用样式',
    confirm: '确认',
    iknow: '我知道了',
    download: '下载',
    cancel: '取消',
    combination: '组合',
    select: '请选择',
    unknown: '未知',
    onTrack: '上轨',
    delete: '删除',
    checkedTip: '已选中 1 个素材'
  },
  components: {
    tagSelect: {
      addTagHint1: '添加标签',
      addTagHint2: '选择以下标签/手动输入标签，按空格确认'
    },
    dialogTrimUpload: {
      title: '快速截取'
    },
    replaceClipModal: {
      title: '片段替换',
      confirm: '替换片段',
      cancel: '取消'
    },
    dialogRename: {
      title: '新建文件夹',
      title2: '重命名',
      label: '文件夹名称',
      ph_name: '文件夹名称'
    },
    dialogSaveAs: {
      title: '另存为',
      label: '新项目名：',
      ph_name: '请输入项目名',
      successMessage: '项目克隆成功',
      failMessage: '项目克隆失败'
    },
    dialogChangelog: {
      title: '版本更新内容'
    },
    dialogFontfamily: {
      title: '免责声明'
    },
    dialogNotPurchase: {
      title: '未购买以下素材',
      name: '素材名称',
      hint: '该模板中包含以下未购买版权素材，请提交到美摄特效素材创作平台，由主账号进行购买操作',
      submit: '提交',
      successMessage: '提交成功',
      failMessage: '提交失败'
    },
    dialogExportProjectXml: {
      title: '此操作会导出{msg}的工程文件，是否继续？'
    },
    animaitonSlider: {
      animationDuration: '动画时长',
      animationPeriod: '动画快慢'
    }
  }
}

export default zh
