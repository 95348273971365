import MonitorEventBusKey from './MonitorEventBusKey'
import ModalEventBus from './ModalEventBus'
import MediaEventBusKey from './MediaEventBusKey'
import UpDownloadEventBusKey from './UpDownloadEventBusKey'
import WebAudioRecordBusKey from './WebAudioRecordBusKey'
// 定义于App 记录鼠标按下并移动的event
const seekMainTimeline = 'seekMainTimeline'
const appMouseMove = 'appMouseMove'
const appMouseUp = 'appMouseUp'
const clipDragging = 'clipDragging'
const clipDragging2 = 'clipDragging2'
const onWebRequestWaitStatusChange = 'onWebRequestWaitStatusChange'
const onPlaybackTimelinePosition = 'onPlaybackTimelinePosition'
const onPlaybackStopped = 'onPlaybackStopped'
const onPlaybackEOF = 'onPlaybackEOF'
const onAudioVUMeter = 'onAudioVUMeter'
const onFinishAssetPackageInstallation = 'onFinishAssetPackageInstallation'
const onPlaybackSeek = 'onPlaybackSeek' // seek 事件 (seekPosition) => {} 微妙
const isMoveSeekBar = 'isMoveSeekBar' // 是否在移动seekBar
const onPlaybackTimelineAppend = 'onPlaybackTimelineAppend' // timeline 上新增或者删除
const onCurrentClipChange = 'changeCurrentClip' // 拿到当前选中的panel中的展示的clip
const refreshTimelineUI = 'refreshTimelineUI'
const refreshAfterApplyTheme = 'refreshAfterApplyTheme'
const refreshTimelineTrackClips = 'refreshTimelineTrackClips'
const buildStartTimelineData = 'buildStartTimelineData'
const changePanel = 'changePanel'
const changePanel1 = 'changePanel1'
const closePanel = 'closePanel'
const uploadFiles = 'uploadFiles'
const saveProjectData = 'saveProjectData'
const isDragSuccess = 'isDragSuccess' // 通知资源库是否拖动成功
const deleteLastMusicLyricsClip = 'deleteLastMusicLyricsClip'     //是否点击应用上轨
const getAdjacentClipKey = 'getAdjacentClipKey' // 获取可以添加转场的clip信息
const isShowTaskPanel = 'isShowTaskPanel'  //是否显示Task框
const musicCaptionUpdate = 'musicCaptionUpdate'
const trackClipSpeedChange = 'trackClipSpeedChange'  //clip变速之后  clip后面的所有clip的inpoint和outPoint都要做校准处理
const editVideoRect = 'editVideoRect'  //编辑rect的时候修改状态 避免数值的改变导致双向绑定的更新
const grabImage = 'grabImage'
const playTimelineWithLowPipeSize = 'playTimelineWithLowPipeSize'
const revocationHandle = 'revocationHandle' // 撤销操作

const changePlaySpeed = 'changePlaySpeed'  // 快捷键修改播放速度
const updatePlaySpeed = 'updatePlaySpeed'  // 更新播放速度

const refreshTransitionUI = 'refreshTransitionUI' // 前边改变了 刷新后面的UI
const refreshTransitionOnBuild = 'refreshTransitionOnBuild'   // 在build完成后刷新转场的UI 因为它的值获取是早于build的  所以在build之后 要重置一下 通过v-if来刷新

const defaultFontHasInstalled = 'defaultFontHasInstalled'   // 默认字体已经安装完成
// 在片段监视器往序列监视器切换时 如果这个切换是由seek触发的  由于timeline的切换 会导致seek先执行 而seek不到正确位置
// 所以通过BUS来发送事件  并在接收时销毁
const seekTimelineOnChangeMonitorType = 'seekTimelineOnChangeMonitorType'
const seekTimeline = 'seekTimeline'
// 在UNDO结束，新建轨道的时候  要刷新被选中的轨道值和clip值 不然还是之前的 会有问题
const setTrackIndexAndClipIndexAfterBuild = 'setTrackIndexAndClipIndexAfterBuild'
const isShowMask = 'isShowMask'
const shouldUpdataMusicLyricsData = 'shouldUpdataMusicLyricsData'
const applyTheme = 'applyTheme'
const applyThemeEdit = 'applyThemeEdit'
const updateDuration = 'updateDuration' // 更新视频时常
const showClipHead = 'showClipHead'
const showClipFoot = 'showClipFoot'
const initMediaEditPanel = 'initMediaEditPanel'
const deleteTheme = 'deleteTheme'
const editTheme = 'editTheme'
const updataSelect = 'updataSelect'
const play = 'play'
const updateLibrettoScroll = 'updateLibrettoScroll'
const setTimelineScroll = 'setTimelineScroll'
const refreshTimelineData = 'refreshTimelineData'
const downOnTrack = 'downOnTrack'
const updateCanvas = 'updateCanvas'
const updateEmptyTrackDuration = 'updateEmptyTrackDuration'
const boxSelect = 'boxSelect'
const drawMultiple = 'drawMultiple'
const setMaskShow = 'setMaskShow'
const onWaitStatusChange = 'onWaitStatusChange'
const changePlay = 'changePlay'
const playStatus = 'playStatus'
const tempUpdateClip = 'tempUpdateClip' // 制作模板, 更新选中的clip
const shouldUpdateSplitData = 'shouldUpdateSplitData'
const updateResolutionForMaterialFetch = 'updateResolutionForMaterialFetch' // 更新画幅比
const updateResolutionSelect = 'updateResolutionSelect'
const updateCurrentTheme = 'updateCurrentTheme' // 更新当前选中的主题id, 用于多画幅切换时, 主题列表内选中状态未更新
const scrollByCode = 'scrollByCode' // 通过代码使得时间线滚动
const createResource = 'createResource' // 直播剪辑跳转云剪辑(创建云剪辑资源)
const deleteKeyFramesAtSelectedTime = 'deleteKeyFramesAtSelectedTime'
const changeCurrentStandIndex = 'changeCurrentStandIndex' // 文字视频，修改当前选中的机位
const mediasMultipleAddClip = 'mediasMultipleAddClip'

// smg
const uploadSelect = 'uploadSelect'
const uploadSelectWithTrim = 'uploadSelectWithTrim'
const uploadProgress = 'uploadProgress'
const uploadError = 'uploadError'
const uploadItmeFinish = 'uploadItmeFinish'
const uploadAllFinish = 'uploadAllFinish'

// yicai
const generateClip = 'generateClip' // 粗剪生成片段

export default {
  ...ModalEventBus,
  ...MonitorEventBusKey,
  ...MediaEventBusKey,
  ...UpDownloadEventBusKey,
  ...WebAudioRecordBusKey,
  updateResolutionForMaterialFetch,
  updateResolutionSelect,
  updateCurrentTheme,
  createResource,
  changeCurrentStandIndex,
  boxSelect,
  onWaitStatusChange,
  changePlay,
  tempUpdateClip,
  playStatus,
  setMaskShow,
  drawMultiple,
  updateCanvas,
  updateEmptyTrackDuration,
  downOnTrack,
  refreshTimelineData,
  appMouseMove,
  appMouseUp,
  clipDragging,
  clipDragging2,
  initMediaEditPanel,
  onCurrentClipChange,
  onWebRequestWaitStatusChange,
  onPlaybackTimelinePosition,
  onPlaybackStopped,
  onPlaybackEOF,
  onAudioVUMeter,
  onFinishAssetPackageInstallation,
  onPlaybackSeek,
  isMoveSeekBar,
  onPlaybackTimelineAppend,
  refreshTimelineUI,
  refreshAfterApplyTheme,
  buildStartTimelineData,
  changePanel,
  changePanel1,
  closePanel,
  saveProjectData,
  isDragSuccess,
  deleteLastMusicLyricsClip,
  getAdjacentClipKey,
  isShowTaskPanel,
  musicCaptionUpdate,
  refreshTimelineTrackClips,
  trackClipSpeedChange,
  editVideoRect,
  grabImage,
  changePlaySpeed,
  updatePlaySpeed,
  refreshTransitionUI,
  playTimelineWithLowPipeSize,
  refreshTransitionOnBuild,
  defaultFontHasInstalled,
  seekTimelineOnChangeMonitorType,
  seekTimeline,
  setTrackIndexAndClipIndexAfterBuild,
  isShowMask,
  shouldUpdataMusicLyricsData,
  applyTheme,
  applyThemeEdit,
  deleteTheme,
  editTheme,
  updateDuration,
  showClipHead,
  showClipFoot,
  updataSelect,
  play,
  updateLibrettoScroll,
  setTimelineScroll,
  seekMainTimeline,
  uploadFiles,
  shouldUpdateSplitData,
  scrollByCode,
  deleteKeyFramesAtSelectedTime,
  uploadSelect,
  uploadSelectWithTrim,
  uploadProgress,
  uploadError,
  uploadItmeFinish,
  uploadAllFinish,
  mediasMultipleAddClip,
  generateClip,
  revocationHandle
}
