import { get, post, delet } from '@/utils/request'
import NvNetwork from '@/api/network/NvNetwork'

export default {

  project_create: (data, { ...props }) =>
    post({
      url: NvNetwork.data().webApi_project_create,
      data,
      headers: {
        params: { ...props }
      }
    }),

  project_update: (data, { ...props }) =>
    post({
      url: NvNetwork.data().webApi_project_update,
      data,
      headers: {
        params: { ...props }
      }
    }),

  project_create_empty: (data, { ...props }) =>
    post({
      url: NvNetwork.data().webApi_project_create_empty,
      data,
      headers: {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        params: { ...props }
      }
    }),

  project_delete: (data, { ...props }) =>
    post({
      url: NvNetwork.data().webApi_project_delete,
      data,
      headers: {
        params: { ...props }
      }
    }),

  project_list: ({ ...props }) => {
    if (!process.env.PERM_LIST.b_startPageNumberZero && process.env.NODE_ENV !== 'development') {
      props.page++
    }
    return get({
      url: NvNetwork.data().webApi_project_list,
      headers: {
        headers: {
          'Cache-Control': 'no-cache'
        },
        params: { ...props }
      }
    })
  },

  project_info: ({ ...props }) =>
    get({
      url: NvNetwork.data().webApi_project_info,
      headers: {
        params: { ...props }
      }
    }),
  
  project_history_list: ({ ...props }) =>
    get({
      url: NvNetwork.data().webApi_project_history_list,
      headers: {
        params: { ...props }
      }
    }),
  
  project_history_save: (data, { ... props }) =>
    post({
      url: NvNetwork.data().webApi_project_history_save,
      data,
      headers: {
        params: { ...props }
      }
    }),
  
  project_clone: (data) =>
    post({
      url: NvNetwork.data().webApi_project_clone,
      data
    }),
    
  project_vresion_list: (props) =>
    get({
      url: NvNetwork.data().webApi_project_version_list,
      headers: {
        params: { ...props }
    }    
  }),
}
