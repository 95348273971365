import axios from 'axios'
import {setAudioWaveData, getAudioWaveData} from './AssetsUtils'
import Utils from './Utils'
import Global from './Global'
// const EventEmitter = require('events');

export default class NvAudioWave {
  constructor (params) {
    // 父容器
    this.container = params.container
    // 判断是否为数组 不然下面调用appendchild方法会报错
    if (Object.prototype.toString.call(this.container) === '[object Array]') {
      this.container = params.container[0]
    }
    this.audioUrl = params.audioUrl?.split(';')[0]
    this.clipContainerWidth = params.clipContainerWidth
    this.clipContainerHeight = params.clipContainerHeight
    this.clipTrimIn = params.clipTrimIn
    this.clipTrimOut = params.clipTrimOut
    this.clipDuration = params.clipDuration
    this.uuid = params.uuid
    // 专门引入clip属性, clip的uuid在container创建时为空，之后在时间线上时被赋值，
    // 由于波形图是放在container上，container的id又是和uuid绑定的，导致了container必须动态按照uuid来查找
    this.clip = params.clip

    if (!this.container) {
      this.container = document.createElement('div')
    }

    this.sampleData = new Uint8Array()
    this.load()
    this.render()
  }
  get isVideo() {
    return this.clip.type === 'video'
  }
  render () {
    this.clipContainer = document.createElement('div')
    this.clipContainer.className = 'clip-container'

    this.style(this.clipContainer, {
      width: this.getOriginClipWidth() + 'px',
      position: 'relative',
      height: this.clipContainerHeight + 'px'
    })
    // 添加前先删除容器中原有得元素，防止重复调用容器中添加过多元素
    this.container.innerText = ''
    this.container.appendChild(this.clipContainer)

    this.waveCanvas = document.createElement('canvas')
    this.waveCanvas.className = 'wave-canvas'

    this.style(this.waveCanvas, {
      width: '100%',
      height: '100%'
    })
    this.clipContainer.appendChild(this.waveCanvas)
  }
  waveDataReady () {
    let sampleData = this.sampleData
    let clipContainerWidth = this.getOriginClipWidth()

    // 65535 is max width of canvas can be render on browser
    if (clipContainerWidth > 65535) {
      clipContainerWidth = 65535
    }

    let resampledData = new Int8Array(Math.floor(clipContainerWidth)*2)
    let averageSampleCount = sampleData.length/2./clipContainerWidth

    for (let i = 0; i < clipContainerWidth; i++) {
      resampledData[i*2] = sampleData[2*(Math.floor(i*averageSampleCount))]
      resampledData[i*2 + 1] = sampleData[2*(Math.floor(i*averageSampleCount)) + 1]
    }
    this.resampledData = resampledData
    this.waveDataResampled()
  }
  waveDataResampled () {
    this.drawWave()
    let image = this.getImage()
    let container = document.querySelector('div[id$="' + this.clip.uuid + '"]')
    this.isVideo && (container = container?.nextElementSibling)
    if (container == null) {
      container = this.container
    }
    this.style(container, {
      backgroundImage: 'url(' + image + ')',
      backgroundColor: this.isVideo ? '#265080' :'#267180'
    })

    let originClipWidth = this.getOriginClipWidth()
    let pos = Math.floor(this.clipTrimIn / this.clipDuration * originClipWidth)
    this.style(container, {
      backgroundPosition: -pos + 'px 0px'
    })

    this.style(this.waveCanvas, {
      display: 'none'
    })
  }
  /*
    画法2: 波峰与波峰之间连线，波谷与波谷之间连线，画一个封闭的区域
    假设采样数 2700个，设为s[0],s[1], ..., s[2699]
    canvas宽度 1000px, 那么平均采样数为 averageSampleCount = 2700/1000 = 2.7
    每个像素的采样值，设为 v[0],v[1], ..., v[999]
    那么计算方式如下： v[0] = s[Math.floor(0*2.7)] = s[0],
                     v[1] = s[Math.floor(1*2.7)] = s[2],
                     v[2] = s[Math.floor(2*2.7)] = s[5],
                     ...
                     v[999] = s[Math.floor(999*2.7)] = s[2697],
   */
  drawWave () {
    let context = this.waveCanvas.getContext('2d')

    let originClipWidth = this.getOriginClipWidth()
    // 65535 is max width of canvas can be render on browser
    if (originClipWidth > 65535) {
      originClipWidth = 65535
    }
    if(this.isVideo) {
      this.clipContainerHeight = Utils.getTrackDOMHeight(this.clip.type) / 3
    }
    this.waveCanvas.width = originClipWidth
    this.waveCanvas.height = this.clipContainerHeight
    context.fillStyle = this.isVideo ? '#356090' : '#9AD7E2'

    context.beginPath()
    let startX = 0.5
    let startY = 128 * this.clipContainerHeight / 256.0
    context.moveTo(startX, startY)
    for (let i = 0; i < originClipWidth; i++) {
      let resampleMax = this.resampledData[2 * i + 1]
      let topPos = (128 - resampleMax) * this.clipContainerHeight / 256.0
      context.lineTo(i + 0.5, Math.floor(topPos))
    }
    for (let i = Math.floor(originClipWidth - 1); i >= 0; i--) {
      let resampleMin = this.resampledData[2 * i]
      let bottomPos = (128 + Math.abs(resampleMin)) * this.clipContainerHeight / 256.0
      context.lineTo(i + 0.5, Math.floor(bottomPos))
    }
    context.lineTo(startX, startY)
    context.closePath()

    context.fill()
  }

  getOriginClipWidth () {
    return Math.floor(this.clipDuration / (this.clipTrimOut - this.clipTrimIn) * this.clipContainerWidth)
  }

  async load () {
    if (Utils.isEmpty(this.audioUrl)) {
      console.warn('Audio url is empty. ')
      return
    }
    let fileName = Utils.getNameFromUrl(this.audioUrl)
    getAudioWaveData(fileName).then((data) => {
      this.sampleData = new Int8Array(data)
      this.waveDataReady()
    }).catch(async () => {
      let _data
      await axios
        .get(this.audioUrl, {
          responseType: 'arraybuffer'
        }).then(({ data }) => {
          _data = data
        }).catch(error => {
          console.log(error)
        })
      this.sampleData = new Int8Array(_data)

      setAudioWaveData(fileName, _data).then(() => {
        console.info('Success to save audio wave data to index db.')
      }).catch(() => {
        console.warn('Failed to save audio wave data to index db.')
      })
      this.waveDataReady()
    })
  }

  notifyWidthChanged (clip, width, changeType) {
    if (changeType == Global.clipZoomChangeType) {
      this.clipContainerWidth = width
      this.waveDataReady()
    } else if (changeType == Global.clipTrimInChangeType) {
      if (this.clipTrimIn == clip.trimIn) {
        return
      }
      let originClipWidth = this.getOriginClipWidth()
      let pos = Math.floor(width - originClipWidth)
      this.style(this.container, {
        backgroundPosition: pos + 'px 0px'
      })
    }
  }

  getImage () {
    return this.waveCanvas.toDataURL('image/png', 1)
  }

  style (el, styles) {
    Object.keys(styles).forEach(prop => {
      if (el.style[prop] !== styles[prop]) {
        el.style[prop] = styles[prop]
      }
    })
    return el
  }
}
